import React, { useEffect, useState } from "react";
import "./currentEvent.css";
import BadgeImage from "../../Components/Badges"
import minusVector from "../../../Asset/Events/minus-vector.svg";
import plusVector from "../../../Asset/Events/plus-vector.svg";
import calendarVector from "../../../Asset/Events/calendar-vector.svg";
import locationVector from "../../../Asset/Events/location-vector.svg";
import timeVector from "../../../Asset/Events/time-vector.svg";
import linkVector from "../../../Asset/Events/link-vector.svg";
import partnerVector from "../../../Asset/Events/partner-vector.svg"
import CheckoutModal from "./CheckoutModal";
import AccordianFaq from "../EventComponents/AccordianFaq";
import ShowSponsors from "../EventComponents/ShowSponsors";
import MoreEvents from "./moreEvents";
import Axios from "axios";
import { format } from "date-fns";
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Link } from "react-router-dom";


const CurrentEvent = () => {
  // Initialize state to store event data
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state
  const [formattedDates, setFormattedDates] = useState({});
  const [isPartnerSel, setisPartnerSel] = useState(false);
  let { eventId } = useParams();
  const [count, setCount] = useState(1);
  // track have-partner in payment card
  const [havePartnerOption, sethavePartnerOption] = useState("");
  const [moreEvents, setMoreEvent] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [checkbox1Checked, setCheckbox1Checked] = useState(false); // State for the first checkbox
  const [checkbox2Checked, setCheckbox2Checked] = useState(false); // State for the second checkbox




  // Fetch data from the backend when the component is mounted
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events`);
        // const res = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events/0e26fbc7-be70-4a66-bebd-f3638e503df4/`);
        //const res = await Axios.get("/event-details.json")

        const filteredData = res.data.events.filter(event => event.EventId === eventId)[0]
        const moreEvents = res.data.events.filter(event => event.EventId !== eventId)
        setMoreEvent(moreEvents);
        setEventData(filteredData); // Set event data from response


        const data = filteredData;

        // Extract and format all relevant dates
        if (data.event_schedule) {
          const startDate = format(new Date(data.event_schedule.StartDate), "do MMM, EEEE, yyyy");
          const endDate = format(new Date(data.event_schedule.EndDate), "do MMM, EEEE, yyyy");
          const registrationStartDate = format(new Date(data.event_schedule.RegistrationStartDate), "dd MMM, EEEE yyyy");
          const registrationEndDate = format(new Date(data.event_schedule.RegistrationEndDate), "dd MMM, EEEE yyyy");
          const StartTimeStamp = format(new Date(data.event_schedule.StartTimeStamp), "hh:mm a");
          const EndTimeStamp = format(new Date(data.event_schedule.EndTimeStamp), "hh:mm a");


          // Store formatted dates in state
          setFormattedDates({
            startDate,
            endDate,
            registrationStartDate,
            registrationEndDate,
            StartTimeStamp,
            EndTimeStamp
          });
        }
        setLoading(false); // Stop loading

      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    //everytime new event selected, count is reset back to 1;
    setCount(1);
    setCheckbox1Checked(false); // Uncheck checkbox 1
    setCheckbox2Checked(false); // Uncheck checkbox 2
    fetchData();
  }, [eventId]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BASE_URL}/files/${eventData.EventImageFileId}/download`,
          {
            responseType: "blob",
          }
        );
        const imageObjectURL = URL.createObjectURL(response.data);
        setImageUrl(imageObjectURL);
      } catch (error) {
        setImageUrl("");
      }
    };

    if (eventData && eventData.EventImageFileId) {
      fetchImage();
    }
  }, [eventData]);

  //Defining size of badge for the current event
  const BadgeSize = { width: '18rem', height: '18rem' };



  //For checkout modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (error) {
    console.log(error)
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <p>Error loading data... {error.message}</p>

      </div>

    ) // Show error message
  }


  // Function to handle increment and decrement
  const increment = () => {
    // Only restrict increment if partner is selected and IsPartnerAllowed is true
    if (eventData.IsPartnerAllowed === false) {
      setCount(prevCount => Math.min(prevCount + 1, eventData.MaxPlayers * eventData.TeamLimit));
    }
  };

  const decrement = () => {
    // Only restrict decrement if partner is selected and IsPartnerAllowed is true
    if (eventData.IsPartnerAllowed === false) {
      setCount(prevCount => Math.max(prevCount - 1, 1));
    }
  };



  // Function to handle partner/team selection
  const handleSelect = (option) => {
    if (option === havePartnerOption) {
      // Deselect if the same option is clicked
      sethavePartnerOption("");
      setCount(1); // Reset count to 1 on deselection
      setisPartnerSel(false); // Reset partner selection state
    } else {
      // Update partner option and count based on the selected option
      sethavePartnerOption(option);
      if (eventData.IsPartnerAllowed) {
        if (eventData.MaxPlayers === 1) {
          setCount(option === "yes" ? 2 : 1); // Set count to 2 if partner, else 1
        } else {
          setCount(option === "yes" ? eventData.MaxPlayers : 1); // Set to max players for team, else 1
        }
      } else if (option === "no") {
        setCount(1); // Reset count to 1 if no partner is selected
      }
      setisPartnerSel(true); // Set partner selection state to true
    }
  };
  return (
    <> {loading ?
      <div className="w-full h-[100vh] flex justify-center items-center">

        <ClipLoader size={50} color={"#2D1066"} loading={loading} />
      </div >
      :
      <div className=" pt-44 flex flex-col md:flex-row w-full">
        {/* Upper Part Event Details */}

        <div className="event-details flex flex-col justify-between items-center md:items-start md:w-[65%] gap-6 ">
          {/* Left Side Event card here  */}
          <div className="   flex flex-col md:flex-row justify-center md:items-start items-center w-full">
            {/* Card Div */}
            <div className="event-card-left w-[95%] rounded-3xl  md:pl-12 md:py-8  md:ml-auto flex flex-col md:flex-row md:gap-10 justify-between items-center md:items-start p-4 relative">
              {/* Left Image */}
              <div className="align-middle  md:w-[33%] md:py-6 w-full p-2">
                {imageUrl ? (
                  <img
                    className=" hidden md:block w-full h-[13rem] mx-auto"
                    src={imageUrl}
                    alt={eventData.EventTitle}
                  />
                ) : ""}
                {imageUrl ? (
                  <img
                    className=" md:hidden w-full h-[13rem]"
                    src={imageUrl}
                    alt={eventData.EventTitle}
                  />
                ) : ""}
              </div>

              {/* Center Text */}
              <section className="written-and-logo flex md:justify-between md:py-6  w-full ">
                <div className="flex flex-col justify-start gap-6  items-start md:w-[60%] py-5 md:py-0 text-[var(--medium-purple)] ">
                  <div className="mobile-flex-view flex md:block items-center ">
                    <span className="xl:text-5xl md:text-3xl text-[1.75rem] font-semibold md:mb-10 font-[manrope] w-[80%] md:w-auto">
                      {eventData.EventTitle}
                    </span>
                    <div className=" md:hidden w-[38%] absolute left-[14.2rem] top-[12rem]">
                      {/* <img
                        className="mx-auto w-full"
                        src={leagueBadge}
                        alt="League Badge"
                      /> */}
                      <BadgeImage EventType={eventData.EventType} size={BadgeSize} />
                    </div>
                  </div>

                  <span className="font-medium md:text-lg text-base font-ClashGrotesk ">
                    {eventData.EventDescription}

                  </span>
                </div>

                {/* Right league badge image */}
                <div className="md:w-[38%]  align-middle md:block hidden -mt-10 -ml-16">
                  {/* <img className="ml-0 md:w-full" src={leagueBadge} alt="League Badge" /> */}
                  <BadgeImage EventType={eventData.EventType} size={BadgeSize} />
                </div>
              </section>
            </div>
          </div>

          {/* Right side payment card for mobile only view here*/}
          <div className="current-event-right-portion w-[95%] md:w-2/5 md:hidden  flex justify-center items-start ">
            {/* Card Div */}
            <div className="amount-card-right md:w-[75%] w-full   bg-[var(--white)] rounded-2xl sticky top-0 flex flex-col justify-between items-center p-3  gap-3 ">
              {/* Show count of ticket */}
              <span className="flex w-[90%] justify-between  items-center">
                <p className="text-base font-manrope text-[var(--dark-blue)] font-[820] pt-3">
                  {eventData.EventTitle}
                </p>
                <span className="flex gap-2">
                  <button onClick={decrement} className={` w-6 h-6 ${eventData.IsPartnerAllowed ? '' : 'cursor-pointer'}`}>
                    <img src={minusVector} alt="minus vector" />
                  </button>
                  <p className="count-of-ticket font-manrope font-semibold text-base">{count}</p>
                  <button onClick={increment} className={`w-6 h-6 ${eventData.IsPartnerAllowed ? '' : 'cursor-pointer'}`}>
                    <img src={plusVector} alt="plus vector" />
                  </button>
                </span>
              </span>

              {/* Partner/Team Selection Logic */}
              {eventData.IsPartnerAllowed && (
                <div className="w-full flex flex-col justify-center items-center">
                  <hr className="text-lg border border-solid border-[var(--dull-white)] w-[93%] mt-1" />
                  <div className="w-[94%] font-manrope font-semibold mt-4">
                    <span className="flex gap-1">
                      <img src={partnerVector} alt="Partner" />
                      <p className="text-base text-[var(--medium-purple)]">
                        {eventData.MaxPlayers === 1 ? "I have a partner to play with" : "I have a team to play with"}
                      </p>
                    </span>

                    <div className="flex w-full items-center justify-between ">
                      <div
                        className={`flex w-[47%] rounded-lg border border-solid border-[#8868C333] justify-between text-base text-[var(--purple-blue)] mt-3 ${havePartnerOption === "yes" ? 'bg-[var(--very-light-purple)] rounded-xl px-3 py-2' : 'px-3 py-2'}`}
                      >
                        <label htmlFor="havePartner" className="text-xs">
                          Yes
                        </label>
                        <input
                          name="havePartner"
                          type="checkbox"
                          checked={havePartnerOption === "yes" && checkbox1Checked}
                          readOnly
                          onClick={() => handleSelect("yes")}
                          onChange={() => {
                            setCheckbox1Checked(true);
                            setCheckbox2Checked(false); // Uncheck checkbox 2 when checkbox 1 is selected
                          }}
                        />
                      </div>

                      <div
                        className={`flex w-[47%] rounded-lg border border-solid border-[#8868C333] justify-between text-xs text-[var(--medium-purple)] mt-3 ${havePartnerOption === "no" ? 'bg-[var(--very-light-purple)] rounded-xl px-3 py-2' : 'px-3 py-2'}`}
                      >
                        <label htmlFor="noPartner">
                          No
                        </label>
                        <input
                          name="noPartner"
                          type="checkbox"
                          checked={havePartnerOption === "no" && checkbox2Checked}
                          onChange={() => {
                            setCheckbox1Checked(false); // Uncheck checkbox 1 when checkbox 2 is selected
                            setCheckbox2Checked(true);
                          }}
                          readOnly
                          onClick={() => handleSelect("no")}
                        />
                      </div>
                    </div>


                    {/* {havePartnerOption === "no" && (
                      <div className="mt-2 pl-2 pr-4 py-3 bg-[#7070700D] font-manrope font-semibold text-sm text-[var(--gray-brown)] rounded-b-3xl">
                        Gotcha! We will help find you a playing partner — and you’ll get a refund in case it doesn’t happen.
                        Please read our <Link to="/terms" target="_blank"><u className="font-bold">Terms of Service</u></Link> to learn more.
                      </div>
                    )} */}
                  </div>
                </div>
              )}

              {/* Horizontal Line */}
              <hr className="text-base border border-solid border-[var(--dull-white)] w-[90%]" />

              <span className="text-[var(--dark-blue)] text-2xl w-[94%] font-normal font-ClashGrotesk pb-1">
                Total:{" "}
                <span className="font-semibold"> ₹{eventData.Price * count}</span>
              </span>

              <button
                onClick={openModal}
                className={`w-[95%]  text-[var(--white)] py-3 rounded-xl font-manrope mb-2 ${eventData.IsPartnerAllowed ? `${isPartnerSel ? 'bg-[var(--purple-blue)]' : 'bg-[var(--button-disable-light-gray)]'}` : 'bg-[var(--purple-blue)]'}`}
                disabled={eventData.IsPartnerAllowed && !isPartnerSel}
              >
                Check Out
              </button>
            </div>
          </div>

          {/* Event Details go here */}
          <div className="para mt-10 md:p-8 md:pr-0 w-full  ">
            <div className="flex flex-col gap-6 2xl:gap-8 w-full text-[var(--dark-blue)] ">
              {/* Event Details Heading */}
              <div className="flex flex-col md:flex-row  w-full p-4 gap-3 md:gap-6 ">
                <div className="md:w-1/3 md:text-[2rem] text-[1.75rem] font-medium font-ClashGrotesk">
                  Event Details
                </div>
                <div className="md:w-2/3 flex flex-col justify-between items-start text-[var(--medium-black)] text-xl  font-manrope font-[630]">
                  <span className="pl-2 flex gap-1 py-2 md:py-1">
                    {" "}
                    <span className="mt-1"><img src={calendarVector} alt="calendar" /></span>

                    {formattedDates.startDate}
                  </span>
                  <hr className="text-base  border border-solid border-[var(--dull-white)] w-[90%] md:w-full my-2 border-opacity-40 hidden md:block" />
                  <span className="pl-2 flex gap-1 py-2 md:py-1">
                    {" "}
                    <span className="mt-1"><img src={timeVector} alt="clock" /></span>
                    {formattedDates.StartTimeStamp} - {formattedDates.EndTimeStamp}
                  </span>
                  <hr className="text-base border border-solid border-[var(--dull-white)] w-[90%] md:w-full my-2 border-opacity-40 hidden md:block" />
                  <span className="pl-2 flex gap-1 py-2 md:py-1">
                    {" "}
                    <span className="mt-1"><img src={locationVector} alt="location" /></span>
                    {" "}
                    {eventData?.venue.venue_address.city},{" "}
                    {eventData?.venue.venue_address.state},{" "}
                    {eventData?.venue.venue_address.country},{" "}
                    {eventData?.venue.venue_address.pincode}
                  </span>
                  <hr className="text-base border border-solid border-[var(--dull-white)] w-[90%] md:w-full my-2 border-opacity-40 hidden md:block" />
                </div>
              </div>

              {/* About one day tournaments heading */}
              <div className="flex flex-col md:flex-row gap-3 md:gap-6  p-4">
                <div className="md:w-1/3 md:text-[2rem] text-[1.75rem]   font-medium font-ClashGrotesk">
                  About One-Day Tournaments
                </div>
                <div className="md:w-2/3 flex flex-col justify-between items-start text-xl  font-manrope font-medium">
                  <span>{eventData.EventDescription}</span>
                </div>
              </div>

              {/* About the sponsors */}
              {eventData?.sponsors?.length > 0 && (
                <div className="flex flex-col md:flex-row gap-3 md:gap-6 2xl:gap-6 p-4">
                  <div className="md:w-1/3 text-[1.75rem] md:text-[2rem] font-medium font-ClashGrotesk">
                    Sponsors
                  </div>
                  <div className="md:w-2/3 flex justify-between items-start text-xl font-manrope font-medium">
                    <ShowSponsors eventData={eventData} />
                  </div>
                </div>
              )}

              {/* Location Link */}
              <div className="flex flex-col md:flex-row gap-3 md:gap-6 2xl:gap-6 p-4">
                <div className="md:w-1/3 md:text-[2rem] text-[1.75rem] font-medium font-ClashGrotesk">
                  Location Link
                </div>
                <div className="md:w-2/3 flex flex-col justify-between items-start text-xl  text-[var(--medium-black)] underline font-manrope font-semibold">
                  <span>
                    <a
                      className="flex gap-1"
                      target="_blank" rel="noreferrer"
                      href={eventData.venue.map_link}
                    >
                      {" "}
                      <img src={linkVector} alt="link vector" /> {eventData?.venue.venue_address.city},{" "}
                      {eventData?.venue.venue_address.state},{" "}
                      {eventData?.venue.venue_address.country},{" "}
                      {eventData?.venue.venue_address.pincode}
                    </a>
                  </span>
                </div>
              </div>

              {/* Event FAQs */}
              <div className="flex flex-col md:flex-row gap-3 md:gap-6  p-4">
                <div className="md:w-1/3 md:text-[2rem] text-[1.75rem]  font-medium font-ClashGrotesk">
                  Event FAQs
                </div>
                <div className="md:w-2/3 flex flex-col justify-between items-start text-base 2xl:text-xl font-manrope font-semibold ">
                  <AccordianFaq eventData={eventData} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right side payment card  here for medium and above devices view*/}
        <div className="current-event-right-portion w-[90%] md:w-[35%] hidden md:flex justify-center items-start">
          {/* Card Div */}
          <div className="amount-card-right md:w-[80%] w-full bg-[var(--white)] rounded-2xl sticky top-[7.5rem] flex flex-col justify-between items-center py-6  gap-6 md:gap-4">
            {/* Show count of ticket */}
            <span className="flex w-[95%] justify-between pl-1 pr-2 items-center gap-2">
              <p className="text-base md:text-lg font-extrabold font-manrope text-[var(--dark-blue)]">
                {eventData.EventTitle}
              </p>
              <span className="flex gap-2">
                <button onClick={decrement} className={`w-6 h-6 ${eventData.IsPartnerAllowed ? 'cursor-default' : 'cursor-pointer'}`}>
                  <img src={minusVector} alt="minus vector" />
                </button>
                <p className="count-of-ticket font-manrope font-semibold text-base">{count}</p>
                <button onClick={increment} className={`w-6 h-6 ${eventData.IsPartnerAllowed ? 'cursor-default' : 'cursor-pointer'}`}>
                  <img src={plusVector} alt="plus vector" />
                </button>
              </span>
            </span>

            {/* Partner/Team Selection Logic */}
            {eventData.IsPartnerAllowed && (
              <div className="w-full flex flex-col justify-center items-center">
                <hr className="text-lg border border-solid border-[var(--dull-white)] w-[93%] mt-1" />
                <div className="w-[94%] font-manrope font-semibold mt-4">
                  <span className="flex gap-1">
                    <img src={partnerVector} alt="Partner" />
                    <p className="text-base text-[var(--medium-purple)]">
                      {eventData.MaxPlayers === 1 ? "I have a partner to play with" : "I have a team to play with"}
                    </p>
                  </span>

                  <div
                    className={`flex justify-between text-base text-[var(--purple-blue)] mt-3 ${havePartnerOption === "yes" ? 'bg-[var(--very-light-purple)] rounded-xl p-3' : 'px-3 py-2'}`}
                  >
                    <label htmlFor="havePartner" className="text-xs">
                      Yes, I have {eventData.MaxPlayers === 1 ? "a partner" : "a team"} to register with.
                    </label>
                    <input
                      name="havePartner"
                      type="checkbox"
                      checked={havePartnerOption === "yes" && checkbox1Checked}
                      readOnly
                      onClick={() => handleSelect("yes")}
                      onChange={() => {
                        setCheckbox1Checked(true);
                        setCheckbox2Checked(false); // Uncheck checkbox 2 when checkbox 1 is selected
                      }}
                    />
                  </div>

                  <div
                    className={`flex justify-between text-xs text-[var(--medium-purple)] mt-2 ${havePartnerOption === "no" ? 'bg-[var(--very-light-purple)] rounded-xl p-3' : 'px-3 py-2'}`}
                  >
                    <label htmlFor="noPartner">
                      No, I’ll need to be paired up with {eventData.MaxPlayers === 1 ? "a partner" : "a team"}.
                    </label>
                    <input
                      name="noPartner"
                      type="checkbox"
                      checked={havePartnerOption === "no" && checkbox2Checked}
                      onChange={() => {
                        setCheckbox1Checked(false); // Uncheck checkbox 1 when checkbox 2 is selected
                        setCheckbox2Checked(true);
                      }}
                      readOnly
                      onClick={() => handleSelect("no")}
                    />
                  </div>

                  {havePartnerOption === "no" && (
                    <div className="mt-2 pl-2 pr-4 py-3 bg-[#7070700D] font-manrope font-semibold text-sm text-[var(--gray-brown)] rounded-b-3xl">
                      Gotcha! We will help find you a playing partner — and you’ll get a refund in case it doesn’t happen.
                      Please read our <Link to="/terms" target="_blank"><u className="font-bold">Terms of Service</u></Link> to learn more.
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Horizontal Line */}
            <hr className="text-lg border border-solid border-[var(--dull-white)] w-[93%]" />

            {/* Total Amount */}
            <span className="text-[var(--dark-blue)] text-3xl md:text-[2rem] w-[94%] font-normal font-ClashGrotesk">
              Total: <span className="font-[520]">₹{eventData.Price * count}</span>
            </span>

            {/* Checkout Button */}
            <button
              onClick={openModal}
              className={`w-[92%]  text-[var(--white)] py-4 rounded-xl font-manrope text-xl ${eventData.IsPartnerAllowed ? `${isPartnerSel ? 'bg-[var(--purple-blue)] cursor-pointer' : 'bg-[var(--button-disable-light-gray)] '}` : 'bg-[var(--purple-blue)] cursor-pointer'} `}
              disabled={eventData.IsPartnerAllowed && !isPartnerSel}
            >
              Check Out
            </button>
          </div>
        </div>
      </div>
    }
      {isModalOpen ? <CheckoutModal isOpen={isModalOpen} closeModal={closeModal} count={count} eventData={eventData} /> : null}

      <MoreEvents eventData={moreEvents} />
    </>
  );
};

export default CurrentEvent;
