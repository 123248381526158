import React from 'react';
import './hero.css';
import HeroLogo from '../../../Asset/logo-image-hero.svg';

const Hero = () => {
  return (
    <section className="hero z-10">
      <img className='ml-6' src={HeroLogo} alt="Netgala"></img>
      <div className="hero-content ">
        <h1 className='font-ClashGrotesk font-medium md:text-7xl 2xl:text-[5rem]' >Your time to play</h1>
      </div>
    </section>
  );
};

export default Hero;