import React, { useState, useEffect } from 'react'

//importing the badge stamp images
import BadgeImage from "../../Components/Badges"
import calendarVector from "../../../Asset/Events/calendar-vector-white.svg"
import timeVector from "../../../Asset/Events/time-vector-white.svg"
import locationVector from "../../../Asset/Events/location-vector-white.svg"
import coinVector from "../../../Asset/Events/coin-vector-white.svg"
import { Link } from 'react-router-dom'
import { format } from "date-fns";
import Axios from "axios";

const UpcomingEvents = () => {

    const [eventData, setEventData] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state
    const [error, setError] = useState(null); // Track error state
    const [formattedDates, setFormattedDates] = useState({});
    const [imageUrl, setImageUrl] = useState("");
    const BadgeSize = { width: "100%", height: "100%" };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events`);
                const data = res.data.events[0];

                setEventData(data); // Set event data from response

                // Extract and format all relevant dates
                if (data.event_schedule) {
                    const startDate = format(new Date(data.event_schedule.StartDate), "do MMM, yyyy");
                    const endDate = format(new Date(data.event_schedule.EndDate), "do MMM, yyyy");
                    const registrationStartDate = format(new Date(data.event_schedule.RegistrationStartDate), "dd MMM, EEEE yyyy");
                    const registrationEndDate = format(new Date(data.event_schedule.RegistrationEndDate), "dd MMM, EEEE yyyy");
                    const StartTimeStamp = format(new Date(data.event_schedule.StartTimeStamp), "hh:mm a");
                    const EndTimeStamp = format(new Date(data.event_schedule.EndTimeStamp), "hh:mm a");

                    // Store formatted dates in state
                    setFormattedDates({
                        startDate,
                        endDate,
                        registrationStartDate,
                        registrationEndDate,
                        StartTimeStamp,
                        EndTimeStamp
                    });
                }

                setLoading(false); // Stop loading

            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await Axios.get(
                    `${process.env.REACT_APP_BASE_URL}/files/${eventData.EventImageFileId}/download`,
                    {
                        responseType: "blob",
                    }
                );
                const imageObjectURL = URL.createObjectURL(response.data);
                setImageUrl(imageObjectURL);
            } catch (error) {
                setImageUrl("");
            }
        };

        if (eventData && eventData.EventImageFileId) {
            fetchImage();
        }
    }, [eventData]);

    return (
        <>
            {!loading ?
                <div className='w-full flex flex-col gap-2 mt-10'>
                    {/* All Upcoming Events Text Here */}
                    <div className='flex justify-end'>
                        {/* ------Don't delete this comment------- */}
                        {/* <span className='flex gap-2 text-2xl  font-manrope font-semibold text-[var(--white)] opacity-[50%] hover:opacity-70 cursor-pointer'>All Upcoming Events <img src={rightArrowVector} alt="right arrow vector" /></span> */}
                        {/* ------Don't delete this comment------- */}
                    </div>

                    <div className="homeAboutUs-event-card mb-12 flex flex-col md:flex-row justify-between items-center w-[95%] md:w-full p-2 py-4 rounded-3xl shadow-xl mx-auto md:p-4 md:pb-6">
                        {/* Inside container left part */}
                        <div className="flex flex-col md:flex-row xl:w-[57%] md:w-[60%] md:h-[95%] w-full items-center p-3 md:gap-4 lg:gap-6 md:p-6 relative">
                            {/* Image here */}
                            <div className="md:hidden  w-full h-[13rem] ">
                                {imageUrl ? (
                                    <img className='w-full h-full' src={imageUrl} alt={eventData.EventTitle} />
                                ) : ""}
                            </div>
                            <div className="hidden md:block w-[32rem] h-[22rem]">
                                {imageUrl ? (
                                    <img className='w-full h-full' src={imageUrl} alt={eventData.EventTitle} />
                                ) : ""}
                            </div>

                            {/* Text part */}
                            <div className="flex flex-col w-full justify-start md:h-full md:mb-auto md:gap-2 md:w-full text-[var(--white)] md:pl-6 ">
                                <div className="flex">
                                    {/* Heading */}
                                    <div className=" md:w-full">
                                        <h1 className="xl:text-5xl lg:text-3xl md:text-2xl text-[1.75rem] font-[470] py-4 md:py-2 text-left font-ClashGrotesk md:mb-2">
                                            {eventData.EventTitle}
                                        </h1>
                                    </div>

                                    {/* Badge image for Mobile view */}
                                    <div className="md:hidden w-[40%] ml-0  absolute left-[14rem] top-[11rem]">
                                        <BadgeImage EventType={eventData.EventType} size={BadgeSize} />
                                    </div>
                                </div>

                                {/* Event Description */}
                                <p className="pb-3 pt-4 md:pt-0 text-left font-manrope lg:text-xl text-sm">
                                    {eventData.EventDescription}
                                </p>
                                <hr className="border border-[var(--medium-purple)] border-solid md:mb-2 mb-4 md:w-[95%] my-3 md:my-0" />

                                {/* Event details */}
                                <div className="flex pb-3 md:pb-2  gap-2 w-full font-manrope text-sm lg:text-base ">
                                    <span className="flex justify-start items-center gap-2 w-full">
                                        <span><img src={calendarVector} alt="calendar" className='w-[1.1rem]' /></span>
                                        {formattedDates.startDate}
                                    </span>
                                    <span className="flex text-left items-center gap-2 w-full">
                                        <span><img src={timeVector} alt="time" className='w-[1.1rem]' /> </span>
                                        <span className="flex-wrap">
                                            <div className="inline-block">{formattedDates.StartTimeStamp}</div>
                                            <div className="inline-block">-</div>
                                            <div className="inline-block">{formattedDates.EndTimeStamp}</div>

                                        </span>
                                    </span>
                                </div>
                                <div className="flex pb-2 gap-2 w-full font-manrope text-sm lg:text-base">
                                    <span className="flex justify-start items-center gap-2 w-full">
                                        <span><img src={locationVector} alt="location" className='w-[1.1rem]' /></span>
                                        {eventData?.venue?.venue_address?.city}
                                    </span>
                                    <span className="flex justify-start items-center gap-2 w-full">
                                        <span><img src={coinVector} alt="price" className='w-[1.1rem]' /></span>
                                        ₹{eventData.Price}
                                    </span>
                                </div>
                                <hr className="border border-[var(--medium-purple)] border-solid md:mt-2 mt-4 md:w-[95%] my-3 md:my-0" />
                            </div>
                        </div>

                        {/* Inside container right part */}
                        <div className="flex flex-col md:justify-between md:items-end  w-full md:w-[30%] md:h-[100%] gap-12  ">
                            <div className='max-w-[18rem] -mt-6  hidden md:block'>
                                <BadgeImage EventType={eventData.EventType} size={BadgeSize} className="hidden md:block w-full h-full" />
                            </div>

                            <Link to={`/events/${eventData.EventId}`} className='mr-12 md:mr-10 w-full flex justify-center pb-2 md:pb-0'>
                                <button className="flex justify-center border border-solid bg-[var(--button-green)] border-[var(--medium-purple)] text-[var(--purple-blue)] text-lg lg:text-xl xl:text-2xl md:py-5 md:w-full py-3 rounded-xl  font-manrope font-bold md:-mt-8 w-[94%] mb-2 md:mb-0">
                                    Register
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                : ""}
        </>
    );
};

export default UpcomingEvents;
