import React from 'react'
import Header from "../Header/header"
import Footer from "../Footer/footer"
import TopContent from './AboutComponents/TopContent'
import MidContent from "./AboutComponents/MidContent"
import BottomContent from './AboutComponents/BottomContent'
import "./About.css"

const About = () => {
    return (
        <div>
            <Header />
            <TopContent />
            <MidContent />
            <BottomContent />
            <Footer />
        </div>
    )
}

export default About
