import React from "react";
import "./homeAboutUs.css";
import padelImg from "../../../Asset/home-about-us-event-1.svg"
import footballImg from "../../../Asset/home-about-us-event-2.svg"
import pickleballImg from "../../../Asset/home-about-us-event-3.svg"
import UpcomingEvents from "./UpcomingEvents";
import { Link } from 'react-router-dom';

const HomeAboutUs = () => {
  return (
    <div className="homepage-lineargradient-bg-color">
      <div className="home-about-us pattern-container z-20">
        <div className="home-about-us-content pt-[3.6rem] p-2 ">
          <div className="home-about-us-content-left text-4xl md:text-5xl lg:text-[3.5rem] font-ClashGrotesk font-[460]">
            <p>Make play part <br /> of your every day</p>
          </div>

          <div className="home-about-us-content-right lg:pl-4 pr-2 ">
            <p className="font-manrope text-[1.18rem] font-[450] pt-4 md:pt-0  ">
              We're Net Gala, India's first exclusive lifestyle community where sports aren't just a hobby – they're a way of life. We're all about helping you rediscover the joy of play and create a life outside the ordinary.
            </p>
            <div className=" w-full flex gap-4 2xl:gap-6 justify-center items-center pt-6 font-manrope text-base font-[700]">

              <Link to="/about" className=" w-full md:w-1/2">
                <button className="rounded-xl text-[var(--home-about-us-ptn-button-grey)] bg-[var(--button-green)] p-2 lg:py-4 w-full">
                  <strong>About Us</strong>
                </button>
              </Link>

              <button
                className="rounded-xl bg-[var(--homeAboutUs-purple-button)] border border-solid border-[var(--dull-white-followUs-button-border)] text-[var(--white)] p-2 lg:py-4 w-full md:w-1/2"
                onClick={() => window.open('https://www.instagram.com/the.netgala?igsh=MXh2MXZkOXF3ejd4YQ%3D%3D&utm_source=qr', '_blank')}
              >
                Follow Us
              </button>
            </div>
          </div>
        </div>


        {/* For Mobile view only */}
        <div className="home-about-us-cards md:hidden carousel  w-[100vw]  md:mb-16 flex   md:px-0">
          <div className="carousel-item  w-[55vw] md:w-[60vw] pl-3">
            <img className="w-full px-2  md:px-4" src={padelImg} alt="padel game"></img>
          </div>
          <div className="carousel-item  w-[55vw] md:w-[60vw]">
            <img className="w-full px-2 md:px-4" src={footballImg} alt="football game"></img>
          </div>
          <div className="carousel-item  w-[55vw] md:w-[60vw] pr-3">
            <img className="w-full px-2  md:px-4" src={pickleballImg} alt="pickleball game"></img>
          </div>
        </div>

        {/* For desktop and medium view */}
        <div className="home-about-us-cards md:flex md:flex-nowrap justify-between items-center w-full md:mb-16 hidden">
          <div className="home-about-us-card w-[32%] ">
            <img className="w-full" src={padelImg} alt="padel game"></img>
          </div>
          <div className="home-about-us-card w-[32%] ">
            <img className="w-full" src={footballImg} alt="football game"></img>
          </div>
          <div className="home-about-us-card w-[32%] ">
            <img className="w-full" src={pickleballImg} alt="pickleball game"></img>
          </div>
        </div>

        <div className="home-about-us-footer font-ClashGrotesk text-[1.75rem] md:text-[3.7rem] font-[470]   text-center">
          <p className="leading-tight">
            Get ready to be part of the most sought-after social  sports community
            ever assembled!
          </p>
        </div>

        <UpcomingEvents></UpcomingEvents>

      </div>
    </div>
  );
};

export default HomeAboutUs;