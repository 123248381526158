import { Axios } from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "react-accessible-accordion/dist/fancy-example.css";

const AccordianFaq = ({ eventData }) => {

  const [Faq, setFaq] = useState({});
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);
  let {eventId} = useParams();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try{
  //       const res = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events/${eventId}/faqs`);
  //       const faqs = res.data;
  //       console.log(res.data);
  //       setFaq(faqs);
  //       setLoading(false);
  //     }
  //     catch(error){
  //       setError(error.message);
  //       setLoading(false)
  //     }

  //   };
  //   fetchData();
  // })

  // Initializing state to store event data
  if (!eventData || !eventData.FAQ || eventData.length === 0) {
    return;
  }

  return (

    <>
      {/* {eventData.FAQ.map((faq) => (
        <div key={faq.FAQID} className="collapse collapse-arrow font-manrope font-semibold w-full md:w-[90%] bg-white rounded-2xl mb-2">
          <input type="checkbox" name="my-accordion-2" />
          <div className="collapse-title text-xl">{faq.title}</div>
          <div className="collapse-content">
            <p className="text-base ">{faq.description}</p>
          </div>
        </div>
      ))} */}
      <div className="collapse collapse-arrow font-manrope font-semibold w-full md:w-[90%] bg-white rounded-2xl mb-2">
        <input type="checkbox" name="my-accordion-2"/>
        <div className="collapse-title text-xl ">This is just for testing</div>
        <div className="collapse-content">
          <p className="text-base ">Need to remove this one</p>
        </div>
      </div>
      <div className="collapse collapse-arrow font-manrope font-semibold w-full md:w-[90%] bg-white rounded-2xl mb-2">
        <input type="checkbox" name="my-accordion-2" />
        <div className="collapse-title text-xl ">This is just for testing</div>
        <div className="collapse-content">
          <p className="text-base ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium nihil saepe amet delectus voluptatibus velit perferendis asperiores, quas culpa beatae libero enim sed at corporis!</p>
        </div>
      </div>
      <div className="collapse collapse-arrow font-manrope font-semibold w-full md:w-[90%] bg-white rounded-2xl mb-2">
        <input type="checkbox" name="my-accordion-2" />
        <div className="collapse-title text-xl ">This is just for testing</div>
        <div className="collapse-content">
          <p className="text-base ">Need to remove this one</p>
        </div>
      </div>
      <div className="collapse collapse-arrow font-manrope font-semibold w-full md:w-[90%] bg-white rounded-2xl mb-2">
        <input type="checkbox" name="my-accordion-2" />
        <div className="collapse-title text-xl ">This is just for testing</div>
        <div className="collapse-content">
          <p className="text-base ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea sunt beatae voluptates perspiciatis enim minima molestias libero recusandae voluptatem. Dolore totam accusantium optio ut. Labore voluptatem eaque enim minima natus numquam? Quo.</p>
        </div>
      </div>


    </>
  );
};

export default AccordianFaq;
