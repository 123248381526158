import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../Header/header';
import Footer from '../Footer/footer';

const PaymentStatus = () => {
  // Initialize state to store payment data, loading state, and error
  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error

  let { paymentId } = useParams();
  console.log(paymentId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/payments/${paymentId}`);
        setPaymentData(res.data); // Set payment data from response
        setLoading(false); // Stop loading
        console.log(res.data);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [paymentId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Check the transaction status and render the corresponding UI
  return (
    <div>
      <Header />
      <div className="h-[80vh] flex justify-center items-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          {paymentData?.TransactionStatus === 'Success' ? (
            <>
              <h1 className="text-3xl font-bold text-green-500">Payment Successful</h1>
              <p className="mt-4 text-lg text-gray-700">
                Thank you for your purchase! Your payment has been successfully processed.
              </p>
              <div className="text-6xl my-6 text-green-500">✅</div>
            </>
          ) : (
            <>
              <h1 className="text-3xl font-bold text-red-500">Payment Failed</h1>
              <p className="mt-4 text-lg text-gray-700">
                Sorry, your payment could not be processed. Please try again.
              </p>
              <div className="text-6xl my-6 text-red-500">❌</div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentStatus;
