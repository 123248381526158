import React from "react";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import "./Terms.css";

const Terms = () => {
  return (
    <div>
      <Header></Header>

      <div className="w-full pt-44 ">
        <div className="terms-heading w-full md:w-4/5 mx-auto text-center  mb-20 font-medium  ">
          <h1 className="xl:text-5xl md:text-4xl text-3xl font-ClashGrotesk text-[var(--medium-purple)] md:text-left">
            Terms And Conditions
          </h1>
        </div>

        <div className="terms-description flex flex-col md:w-4/5 w-[90%] text-justify mx-auto font-manrope md:text-lg text-sm bg-[--white] p-4 rounded-2xl text-[var(--dark-blue)] font-semibold mb-20 gap-4">
          <p className="md:text-lg text-base pt-1">
            Net Gala Sports Pvt. Ltd. (hereinafter referred to as the “Net
            Gala”, “NG”, “we”, “us”, “our” and terms of similar meaning) owns
            and operates the website (https:/thenetgala.in/) and domain name,
            and any other linked pages, features, content, mobile applications,
            or any other services we offer from time to time in connection
            therewith, to provide various fitness and wellness sessions, sports
            coaching, sporting events, different avenues such as social events,
            sports competitions, fitness based getaways etc., for women to
            network, or simply find support in one another, personally and
            professionally, either virtually (“Virtual Sessions”) or through
            physical sessions (“Physical Sessions”).
          </p>

          <p>
            These Terms and Conditions (“Terms”) govern your (hereinafter
            referred to as “you”, “your”, “User”, as applicable) access to
            and/or use of (either as a registered user, visitor/viewer):
          </p>

          <ul className="terms-lowerAlphabet-style-list list-decimal list-outside pl-2 md:pl-4 flex flex-col gap-4">
            <li>
              <a
                className="text-[var(--reddish-brown)] md:hover:text-xl md:hover:text-[var(--dark-blue)] "
                href="https://thenetgala.in/"
                target="_blank" rel="noreferrer"
              >
                <u>https://thenetgala.in/</u>
              </a>{" "}
              including any sub-domains thereof (collectively, “Site”);
            </li>
            <li>
              all related widgets, tools, applications, data, software, APIs,
              mobile, tablet and other smart device applications (collectively,
              “Application”);
            </li>
            <li>Services (as defined herein below);</li>
          </ul>
          <p>provided by Net Gala from time to time.</p>

          <p>
            The Site and the Application together are hereinafter collectively
            referred to as the “Platform”.
          </p>
          <p>
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000
            (together with any statutory amendments or modifications thereof).
            This electronic record is generated by a computer system and does
            not require any physical or digital signatures.
          </p>
        </div>

        {/* Second Protion of Terms and Conditions */}
        <div className="terms-description flex flex-col md:w-4/5 w-[90%] text-justify mx-auto font-manrope md:text-lg text-sm bg-[--white] p-4 rounded-2xl text-[var(--dark-blue)] font-semibold mb-20 gap-4">
          <div>
            <p className="md:text-xl md:pt-14 pt-4 ">
              PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE PLATFORM. THESE
              TERMS CONSTITUTE A BINDING, LEGAL AGREEMENT BETWEEN YOU AND NET
              GALA. IN ORDER TO USE THE PLATFORM AND/OR AVAIL OF THE SERVICES,
              YOU MUST AGREE TO THESE TERMS BY CLICKING ON THE ‘I AGREE’ BUTTON.
              IF YOU DO NOT AGREE WITH THESE TERMS IN THEIR ENTIRETY, YOU ARE
              NOT ENTITLED TO USE THE PLATFORM OR AVAIL THE SERVICES PROVIDED BY
              US.
            </p>
          </div>
          <ul className=" list-decimal list-outside pl-2 md:pl-4 flex flex-col gap-4">
            {/* First Main Point Here */}
            <li>DEFINITIONS</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-lg text-base flex flex-col gap-2">
              <li>
                Net Gala Sports Pvt. Ltd. (hereinafter referred to as “Net
                Gala”, “NG”, “we”, “us”, “our” and terms of similar meaning)
                owns and operates the website (https://thenetgala.in/) and
                domain name, and any other linked pages, features, content,
                mobile applications, or any other services we offer from time to
                time in connection therewith (collectively referred to as the
                “Platform”), to provide various sports and fitness sessions,
                sports coaching, sporting events, different avenues such as
                social events, sports tournaments and leagues etc., for young
                adults to network, or simply find support in one another,
                personally and professionally, either through Virtual Sessions
                or Physical Sessions.
              </li>
              <li>
                For the purpose of these Terms,
                <ul className="terms-lowerAlphabet-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-1">
                  <li>
                    “Services” means the providing of various fitness, wellness
                    and sports sessions and such other related events, which may
                    be conducted by Net Gala through its Service Professionals,
                    either through Virtual Sessions or through Physical
                    Sessions, by way of listing the upcoming events on the
                    Platform and enabling the Users to book appointments/slots
                    for such upcoming events through the Platform and providing
                    related products or equipment for use or purchase to the
                    User; or such other services as may be provided by Net Gala
                    from time to time on the Platform.
                  </li>
                  <li>
                    “Price” means the purchase price of the Services available
                    for booking on the Platform.
                  </li>
                  <li>
                    “Booking Order” means the order for Services electronically
                    placed on the Platform.
                  </li>
                </ul>
              </li>
              <li>
                We may, from time to time, release new features on the Platform,
                or introduce other Services. Any such additions/ amendments will
                be subject to these Terms as well as any additional terms and
                conditions that we may release for those specific Services or
                features.
              </li>
            </ul>

            {/* Second Main Point Here */}
            <li>ACCEPTANCE AND GOVERNANCE OF TERMS</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-lg text-base flex flex-col gap-2">
              <li>
                You hereby expressly acknowledge and agree to be bound by these
                terms, policies and guidelines incorporated by reference in
                these Terms, including the Privacy Policy, as may be amended
                from time to time.
              </li>
              <li>You represent and warrant that:</li>

              <ul className="terms-lowerAlphabet-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-1">
                <li>
                  You are at least 18 (Eighteen) years of age or in the event
                  you are under 18 (Eighteen) years of age, you have been duly
                  represented by your legal guardian;
                </li>
                <li>
                  You have the lawful authority and capacity to contract and be
                  bound by these Terms;
                </li>
                <li>
                  If you are accepting these Terms on behalf of a company,
                  limited liability partnership, trust or other legal entity,
                  you have the authority to bind such entity to these Terms and,
                  in such event, “You” and “Your” as used in these Terms shall
                  refer to such entity; and
                </li>
                <li>
                  You will comply with all applicable laws and regulations.
                </li>
              </ul>

              <li>
                These Terms are subject to change at any time without notice. To
                make sure you are aware of any changes, please review these
                Terms periodically. Continued use of the Platform or Services
                after any such changes shall constitute your consent to such
                changes.
              </li>
              <li>
                These Terms are published in compliance with, and is governed by
                the provisions of applicable Indian laws, including but limited
                to:
              </li>

              <ul className="terms-lowerAlphabet-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-1">
                <li>the Indian Contract Act, 1872;</li>
                <li>
                  the Information Technology Act, 2000 and the rules,
                  regulations, guidelines and clarifications framed there under,
                  including the Information Technology (Reasonable Security
                  Practices and Procedures and Sensitive Personal Information)
                  Rules, 2011; and
                </li>
                <li>
                  The Information Technology (Intermediaries Guidelines) Rules,
                  2011.
                </li>
              </ul>
            </ul>

            {/* Third Main Point Here */}
            <li>MEMBERSHIP</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                It is not mandatory to register to visit, access and use the
                Platform. However, access to the Services is only available to
                registered Users. You need to register and create a user account
                (“Account”) to avail the Services on the Platform by providing,
                your name, e-mail address, phone number and other details as may
                be requested on the Platform. By registering and creating your
                Account on the Platform, you agree to:
                <ul className="terms-lowerAlphabet-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-1">
                  <li>
                    provide accurate, current and complete information as may be
                    prompted (“Registration Data”) and maintain and undertake to
                    update the same in respect of any changes;
                  </li>
                  <li>
                    maintain the security of your password for the Account;
                  </li>
                  <li>
                    accept all risks of unauthorized access to the Registration
                    Data and any other information you provide to us;
                  </li>
                  <li>
                    notify us immediately of any breach of security or any
                    unauthorized use of your Account;
                  </li>
                  <li>
                    receive promotional communication and newsletters upon
                    registration. You may opt out by contacting the customer
                    service;
                  </li>
                  <li>
                    not authorize, assign or otherwise transfer your Account to
                    any other person or entity or to let them operate through
                    the account created and in no event use another User’s
                    Account for any purpose or objective; and
                  </li>
                  <li>
                    be responsible for all activity on your Account and to use
                    and operate the same in accordance with applicable laws.
                  </li>
                </ul>
              </li>

              <li>
                You further agree and confirm that:
                <ul className="terms-lowerAlphabet-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-1">
                  <li>
                    You are at least 18 (eighteen) years of age and if you are
                    below 18 (eighteen) years of age, you will obtain the
                    permission of your parent or guardian before using our
                    Platform.
                  </li>
                  <li>
                    You will use the Platform and the Service provided by Net
                    Gala, for lawful purposes only and comply with all
                    applicable laws and regulations while using the Platform and
                    availing the Services therein;
                  </li>
                  <li>
                    You will provide authentic and true information in all
                    instances where such information is requested of you. Net
                    Gala reserves the right to confirm and validate the
                    information and other details provided by you at any point
                    of time. If upon confirmation your details are found not to
                    be true (wholly or partly), it has the right in its sole
                    discretion to reject the registration and debar you from
                    using the Services and / or Platform without prior
                    intimation;
                  </li>
                  <li>
                    You authorise Net Gala to contact you for providing any of
                    the Services as requested by you, related to your Account.
                  </li>
                </ul>
              </li>
            </ul>

            {/* Fourth Main Point Here */}
            <li>USE OF THE SERVICE</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                Upon creation of your Account, you shall be entitled to avail
                the Services through the Platform. We shall provide the Services
                vide our employees, agents, contractors and/or representatives
                (“Service Professionals”), based on the requirements of the User
                and based on the Services that the User has signed up for. In
                addition to these Terms, the User also agrees to be bound by the
                specific terms and conditions as may be applicable and as may be
                published at the time of signing up for the specific Services
                availed by the User
              </li>
              <li>
                Promotional offers: Net Gala may at its discretion run
                promotional offers on the Platform in respect of certain
                Services, subject to such terms and conditions as may be
                prescribed. All promotional offers shall be subject to the terms
                and conditions governing the same, as displayed on the Platform
                and/or communicated to you. The User hereby agrees and
                acknowledges that Net Gala shall have the sole discretion to run
                promotional offers, modify the terms and conditions governing
                the same and discontinue the promotional offers with no prior
                intimation to the User, at its sole discretion. The User shall
                have no right, claim or authority to dispute the continuance or
                discontinuance of promotional offers by Net Gala
              </li>
            </ul>

            {/* Fifth Main Point Here */}
            <li>BOOKING ORDERS AND PAYMENTS</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                All Booking Orders placed for the Services through the Platform
                are subject to our acceptance and confirmation of payment.
                Payments once made shall be non-refundable, non-transferable and
                inclusive of all applicable taxes, cess, levies, but shall be
                subject to withholding taxes, as applicable.
              </li>
              <li>
                The Prices indicated on the Platform for the relevant Services
                will be as determined by Net Gala or its respective Service
                Professionals, as the case may be, and is including taxes and
                other applicable charges as mentioned on the Platform. The
                Prices and Services may change at Net Gala’s’ sole discretion
                and Net Gala does not guarantee that the Prices will be the
                lowest in the city, region or geography or if the Services will
                be available for delivery / performance at all times. For
                Services, you may be offered (a) pay per session; or (b)
                subscription options, which could be monthly, quarterly, half
                yearly, annually. For the purposes of monthly and yearly
                subscriptions, a month constitutes 30 calendar days and a year
                constitutes 365 calendar days.
              </li>
              <li>
                Net Gala will commence providing Services only upon receipt of
                User’s payment and for the duration contracted for, to the best
                of its ability.
              </li>
              <li>
                All payments to be made through the Platform shall be made using
                (i) credit /debit card payment where you are the cardholder of
                that card, (ii) bank transfer or (iii) cash, if such option is
                provided by us or in the event cash payment option has been
                accepted by us prior to availing Services (iv) through other
                specified channels and in accordance with such procedures as we
                may from time to time specify.
              </li>
              <li>
                The User agrees and acknowledges that Net Gala uses third-party
                payment processors for any payments made on the Platform. The
                processing of all payments will be subject to the terms,
                conditions and privacy policies of the payment processors in
                addition to these Terms. The User further agrees and
                acknowledges that neither NG nor any of its partners, employees,
                shareholders or other representatives shall be liable to the
                User under any circumstances for any direct, indirect, punitive,
                incidental, special or consequential damages that result from or
                arise out of use of the payment processors.
              </li>
              <li>
                We may from time to time offer discounts for
                purchases/subscription to Services on the Platform, through the
                use of loyalty reward points or promotional discount codes found
                on digital coupons and which may apply to certain specified
                purchases/subscription to Services made through the Platform.
                Only one promotional discount code can be applied to a single
                Booking Order. Once a promotional discount code is used, the
                relevant digital coupon will automatically be redeemed against
                the purchase amount. Digital coupons must be used prior to their
                expiration date indicated. Digital coupons cannot be replaced if
                lost, stolen, or if you should decide to cancel Booking Order.
                In the event where the total Booking Order value is below the
                value of the digital coupon, the excess value will not be
                refunded. Digital coupons will not be applicable with any other
                promotions, unless otherwise stated. If there is a conflict
                between these Terms and the terms and conditions as may be
                stated in the digital coupon, the terms and conditions stated in
                each digital coupon shall prevail.
              </li>
            </ul>

            {/* Sixth Main Point Here */}
            <li>CANCELLATION AND REFUND POLICY</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                No cancellation or refund of payment request shall be
                entertained, once Booking Orders and payments are confirmed by
                NG
              </li>
              <li>
                As some experiences or Services are dependent on weather,
                technology or such other factors beyond our control, NG reserves
                the right to postpone and / or cancel any Booking Order. In
                case, NG cancels any Booking Order, then we shall refund to you,
                the Price of any purchase/subscription even after the relevant
                Booking Order is confirmed and payment has been received by us.
                In the event of such cancellations, NG will make best efforts to
                inform you in advance.
              </li>
              <li>
                In the event of any technical issues faced by the Users during
                the course of availing Services through any of the Virtual
                Sessions as may be organized by NG, due to which all the Users
                who have signed up for such Service has not been able to avail
                such Service, NG may either hold another Virtual Session(s) or
                initiate refund of payment to the User’s account, provided that
                NG determines in its sole discretion that such technical issues
                or any other issues beyond the control of NG, are faced by all
                or most of the Users who have signed up for the Services and
                such issue is due to a technical fault at NG’s end or for such
                other reasons directly attributable to NG. It is however
                clarified that, if NG in its sole discretion determines that
                such technical issues are faced by a particular User due to poor
                internet connection or any other network issues from their side,
                then NG shall not be liable to refund such Price to the User.
              </li>
            </ul>

            {/* Seventh Main Point Here */}
            <li>TERMS FOR AVAILING OF SERVICES</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                Subject to payment of necessary Price / purchase of required
                membership and availability of slots, you shall be permitted to
                book and participate in various underlying Services provided by
                NG either through Virtual Sessions or through Physical Sessions
                and allied services. NG shall not entertain any requests for
                changing of your booked slot to a different slot or rescheduling
                of your booking.
              </li>
              <li>
                In order to participate in the Virtual Sessions or Physical
                Sessions, you must book the same in advance through the
                Platform. Further, you may also need to comply with certain
                technical requirements to participate in the Virtual Sessions,
                as may be notified to you from time to time.
              </li>
              <li>
                Any memberships / single sessions bought or booked by You are
                non-transferrable and non-refundable.
              </li>
              <li>
                For availing fitness and sports related Services, you must
                ensure that you are physically and medically fit to avail the
                said Services. You must understand Your physical and medical
                limits and avail the Services within the said limits.
              </li>
              <li>
                You agree that availing the Services, by their very nature,
                include certain inherent risks that cannot be eliminated
                regardless of the care taken to avoid injuries and/or any kind
                of losses. You hereby assert that your availing of the Services
                is voluntary and that you knowingly assume all such risks and
                hence hereby release NG and relevant Service Professionals of
                all liability arising out of such aforementioned risks. NG and
                relevant Service Professionals do not assume any liability or
                make any warranties of any kind, express or implied, arising out
                of, in connection with or concerning the Services.
              </li>
              <li>
                {" "}
                You agree and permit NG to record audio and / or video of any
                Virtual Sessions for quality and safety purposes, which may be
                referred to in case of any complaints / disputes.
              </li>
              <li>
                During Physical Sessions conducted at gyms, studios, fitness
                centers, training centers, field or any other premises, NG shall
                not, at any time, be liable for any personal belongings of the
                Users including any loss of belongings due to theft/damage or
                otherwise and the Users shall be solely responsible in respect
                of their belongings.
              </li>
            </ul>
            {/* Eighth Main Point Here */}
            <li>ACCESS AND USE</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                NG grants you a limited, personal, non-exclusive,
                non-transferrable and non-sub licensable right to use the
                Platform and Services, solely for your own personal,
                non-commercial use, subject to the Terms. Your access and use of
                the Platform and Services is subject to the following
                representations and warranties:
              </li>
              <ul className="terms-lowerAlphabet-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-1">
                <li>
                  You may only access the Platform or Services using authorized
                  and lawful means;
                </li>
                <li>
                  We shall not be liable for any failure or default to provide
                  access to the Platform on account of any failure or delay by
                  you to register with the Platform for such access or due to
                  any other reasons whatsoever;
                </li>
                <li>
                  Any configuration or set up of the Devices for access to the
                  Platform and the Services shall be your sole responsibility.
                  “Device” means a device, usually electronic, that processes
                  data according to a set of instructions, which may include but
                  not restricted to workstations, personal computers, laptops,
                  netbooks, personal digital assistants, tablets, and
                  smartphones;
                </li>
                <li>
                  We collect, store, process and use your information in
                  accordance with NG Privacy Policy (“Privacy Policy”). By using
                  the Platform and/ or by providing your Personal Information
                  (as defined in the Privacy Policy), you consent to the
                  collection and use of the information you disclose to us, in
                  accordance with the Privacy Policy;
                </li>
                <li>
                  You will not take any action that interferes with, degrades or
                  adversely affects NG and/or the Services and/or the Platform;
                </li>
                <li>
                  You will not use the Platform in a manner (i) that is
                  prohibited by any law or regulation, or facilitates the
                  violation of any law or regulation; or (ii) will disrupt a
                  third parties’ similar use; (iii) violate or tamper with the
                  security of the Platform;
                </li>
                <li>
                  You will not use the Platform, or any portion thereof, to
                  transmit, publish, post, upload, distribute or disseminate any
                  inappropriate, harassing, abusive, defamatory, libellous,
                  obscene, illegal or deceptive content or to sell or promote
                  any products on the Platform;
                </li>
                <li>
                  You will ensure that the Platform is not used to upload, post,
                  transmit, or otherwise make available any content that
                  contains a virus or any other form of malicious code or data
                  that is likely or intended to have an adverse impact on, or
                  provide unauthorized access to, the Platform or any other
                  software, hardware, services or data;
                </li>
                <li>
                  You shall not use any automated system, including but not
                  limited to, “robots”, “spiders”, “offline readers”,
                  “scrapers”, etc., to access the Platform;
                </li>
                <li>
                  You will not attempt to gain unauthorised access to any
                  accounts, Service Professionals’ information, computer systems
                  or networks connected to the Platform, including but not
                  limited to, names, addresses, phone numbers, or email
                  addresses, copying copyrighted text, through hacking, or any
                  other means, or obtain or attempt to obtain any materials or
                  information through any means not intentionally made available
                  to you;
                </li>
                <li>
                  You will not use, misuse or misappropriate the Platform to
                  develop, or to assist anyone in developing a competitive
                  platform, service or for other competitive purposes;
                </li>
                <li>
                  You will not copy, distribute, or make derivative works of the
                  Platform or any content in the Platform in any medium;
                </li>
                <li>
                  You shall not recruit, solicit, or contact in any form the
                  Service Professionals for employment or contracting for a
                  business not affiliated with NG;
                </li>
                <li>
                  You shall not intentionally submit on the Platform any
                  incomplete, inaccurate or false information;
                </li>
                <li>
                  You shall be solely responsible for (i) procuring and
                  maintaining your network connections and telecommunications
                  links from your systems to NG data centres, and (ii) all
                  problems, conditions, delays, delivery failures and all other
                  loss or damage arising from or relating to your network
                  connections or telecommunications links or caused by the
                  internet; and
                </li>
                <li>
                  You acknowledge that from time to time, NG may apply Upgrades
                  (hereinafter defined) to the Platform, and that such Upgrades
                  may result in changes to the appearance and/or functionality
                  of Platform. You may be required to install certain Upgrades
                  or updates to the software in order to continue to access or
                  use the Platform, or portions thereof. “Upgrades” means new
                  versions of, and updates to, Platform whether for the purpose
                  of fixing an error, bug or other issue in the Platform or
                  enhancing the functionality of Platform.
                </li>
              </ul>
            </ul>

            {/* Ninth Main Point Here */}
            <li> FEEDBACK</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                . As a visitor/User of the Platform, you agree to use careful,
                prudent, and good judgment when leaving feedback for other Users
                of the Platform. In the event the feedback violates these Terms,
                is inappropriate or violates propriety or privacy of another
                user, NG, in its sole discretion, may take any of the following
                actions: (i) delete your feedback or any of your postings; (ii)
                limit your Account privileges; (iii) suspend your Account; and
                (iv) report to law enforcement authorities any actions that may
                be illegal, and any reports it receives of such conduct. When
                legally required or at NG discretion, NG will cooperate with law
                enforcement agencies in any investigation of alleged illegal
                activity on this Platform.
              </li>
              <li>
                Reporting inappropriate use of feedback: You may contact NG
                regarding any inappropriate use of feedback via-email to the
                Grievance Redressal Officer (details of which are provided
                below).
              </li>
              <li>
                NG does not and cannot review every posting made on the
                Platform. These Terms do not require NG to monitor, police or
                remove any postings or other information submitted by you or any
                other user and NG shall not be responsible for any ensuing
                liability.
              </li>
            </ul>

            {/* Tenth Main Point Here */}
            <li>INTELLECTUAL PROPERTY RIGHTS</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                . NG and its licensors, as the case may be expressly reserve all
                the intellectual property rights in all text, programs,
                products, processes, technology, content and other materials
                apart from Employer Related Information and Personal
                Information, which appear on this Platform including the
                trademarks, logos and service marks displayed on the Platform
                (“Marks”). You hereby acknowledge that any and all of the
                intellectual property rights (including but not limited to all
                copyright, patent, Marks, etc.) and other proprietary rights in
                and in relation to the Platform including without limitation any
                derivatives, improvements or modifications which ownership is
                directly attributable to NG or its licensors as the case maybe
                (expressly excluding any information which belongs to a User or
                other third party) and any suggestions, ideas, enhancement
                requests, feedback, recommendations or other information
                provided by you or any other party relating to the Platform or
                the Services shall vest wholly, completely and fully with NG or
                its licensors throughout the territory of the world and you
                shall have no right or claim to such intellectual property in
                any manner whatsoever. All rights, including copyright, in this
                Platform are owned by NG. Any use of this Platform or its
                contents, including copying or storing it or them in whole or
                part, other than for your own personal, non-commercial use is
                prohibited without the permission of NG. You may not modify,
                distribute or re-post anything on this Platform for any purpose.
                All software used on this Platform is the property of NG or its
                licensors and protected by Indian and international copyright
                laws.
              </li>
            </ul>

            {/* Eleventh Main Point Here */}
            <li>INDEMNIFICATION</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                By accepting these Terms and using the Platform and/or availing
                the Services, you agree that you shall defend, indemnify and
                hold NG, its partners, employees, shareholders, officers and
                other representatives harmless from and against any and all
                claims, costs, damages, losses, liabilities and expenses
                (including attorneys’ fees and costs) arising out of or in
                connection with: (i) Misuse of your access to and use of the
                Platform and/or the Service and/or misuse of any information on
                the Platform; (ii) any loss or injury to NG representatives or
                Service Professionals resulting from or attributable to your
                acts or omissions; (iii) your violation or breach of these Terms
                or any applicable law or regulation; (iv) Your violation of any
                rights of any third party including any intellectual property
                rights; or (vi) any and all third-party claims based upon (A)
                the content of any communications transmitted by you; and/or (B)
                transactions undertaken by you; through the Platform.
              </li>
            </ul>

            {/* Third Main Point Here */}
            <li>DISCLAIMER OF WARRANTIES</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                NG hereby explicitly and specifically disclaims any and all
                representations, warranties or guarantees in respect of the
                Platform and/or the Services, whether written, oral, expressed
                or implied including, without limiting the generality of the
                foregoing, any warranty of merchantability, quality or fitness
                for a particular purpose.
              </li>
              <li>
                The Platform is provided strictly on an “as is” basis.
                Notwithstanding anything contained in these Terms, NG does not
                warrant that any Platform: (i) will perform error-free or
                uninterrupted, or that NG will correct all or any errors or
                defects (ii) will operate in combination with the Devices, or
                with any other hardware, software, systems or data not provided
                by NG, (iii) will meet the User’s requirements, specifications
                or expectations or that the Services will be available at any
                particular time or location, uninterrupted or secure.
              </li>
              <li>
                NG reserves the right at any time and from time to time to
                modify or discontinue, temporarily or permanently, the Platform
                and/ or the Services (or any part thereof) with or without
                notice and in its sole discretion. You agree that NG shall not
                be liable to you or to any third party for any modification,
                suspension or discontinuance of the Platform or the Services.
              </li>
              <li>
                NG shall not be liable to You for any delay or failure in
                performance of the Services arising out of a cause beyond its
                control and without its fault or negligence. Such causes may
                include, but are not limited to fires, floods, earthquakes,
                strikes, unavailability of necessary utilities, blackouts, acts
                of God, acts of declared or undeclared war or acts of regulatory
                or governmental agencies.
              </li>
              <li>
                The User acknowledges that NG does not control the transfer of
                data over the communications facilities, including the internet,
                and that any Platform may be subject to limitations, delays, and
                other problems inherent in the use of such communications
                facilities. NG shall not be responsible for any (i) delays,
                delivery failures, or other damages as a result; (ii) issues
                related to the performance, operation or security of any
                Platform that arise from the User’s content or third party
                content.
              </li>
              <li>
                NG does not make any representation or warranty regarding the
                reliability, accuracy, completeness, correctness, or usefulness
                of third party content, and disclaims all liabilities arising
                from or related to third party content.
              </li>
              <li>
                We are not responsible for disputes, claims, losses, injuries,
                or damage of any kind that might arise out of or relate to
                conduct of the Users and the Third Party Sites, including, but
                not limited to, any User's reliance upon any information
                provided therein. The Third Party Sites and us are independent
                contractors and neither party has authority to make any
                representations, warranties or commitments on behalf of the
                other.
              </li>
              <li>
                You agree and understand that you shall be responsible for
                ensuring compliance with the terms of use, guidelines, operating
                rules and policies of Third Party Sites. Further under no
                circumstances shall NG be liable to you or the Third Party Sites
                for the services provided by such Third Party Sites to you. In
                the event of any conflict between these Terms and any of the
                terms, conditions and notices contained in any Third Party
                Sites, the contents of these Terms shall prevail.
              </li>
              <li>
                We are not responsible for the content, accuracy or opinions
                expressed in any Third Party Sites, and such Third Party Sites
                are not investigated, monitored or checked for accuracy or
                completeness by us. Inclusion of any linked Third Party Sites on
                Our Platform does not imply approval or endorsement of the Third
                Party Sites by us.
              </li>
              <li>
                We reserve the right to modify or remove any content from the
                Platform, which in our reasonable opinion and discretion, does
                not comply with the above Terms, or if any content is posted
                that we believe is not in our best interest.
              </li>
            </ul>

            {/* Thirteenth Main Point Here */}
            <li>LIMITATION OF LIABILITY</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                In no event shall NG or anyone else involved in administering,
                distributing or providing the Platform an/or Services be liable
                for any direct, special, exemplary, consequential, incidental,
                punitive or indirect damages including without limitation
                damages for loss of profits, goodwill, use, data or other
                intangible losses, that results from the use of, or inability to
                use the Platform and/or Services. NG or anyone else involved in
                administering, distributing or providing the Platform and/or
                Services further explicitly disclaim any and all liability for
                any of the following:
              </li>

              <ul className="terms-lowerAlphabet-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-1">
                <li>
                  errors, mistakes or inaccuracies of the content displayed on
                  the Platform;
                </li>
                <li>
                  personal injury or property damage of any nature whatsoever,
                  resulting from our Services or for any alleged or actual
                  damages or loss of valuables at the location;
                </li>
                <li>
                  the acts or omissions of our representatives performing
                  Services on our behalf;
                </li>
                <li>any failure or delay in the Services;</li>
                <li>any content uploaded on the Platform;</li>
                <li>
                  any loss or damage arising out of your failure to adhere to
                  your obligations under the Terms.
                </li>
                <li>
                  user content or the defamatory, offensive, or illegal conduct
                  of any third party;
                </li>
                <li>
                  viruses, computer viruses or other harmful, disabling computer
                  code, computer instructions, circuitry or other technological
                  means whose purpose is to disrupt, damage or interfere with
                  any computer and communications facilities or equipment
                  (“Harmful Code”) that may be transferred to your Devices when
                  accessing the Platform. By way of clarification, Harmful Code
                  shall include, without limitation, any code containing
                  viruses, Trojan horses, worms or like destructive code or code
                  that was intentionally written to self-replicate. You are
                  advised to obtain and use appropriate anti-virus and security
                  software and to take all other appropriate measures to
                  safeguard the integrity of your Devices.
                </li>
              </ul>

              <li>
                YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT OF
                (I) YOUR USE OF THE PLATFORM; AND/OR (II) AVAILING ANY SERVICES,
                REMAINS SOLELY WITH YOU.
              </li>
              <li>
                {" "}
                In no event shall NG or anyone else involved in administering,
                distributing or providing the Platform and/or Services be liable
                to you for any claims, proceedings, liabilities, obligations,
                damages, losses or costs for an amount exceeding the money
                charged by NG from the User under which such liability has
                arisen and been established.
              </li>
            </ul>

            {/* Fourteenth Main Point Here */}
            <li>TERMINATION</li>

            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                These Terms will continue to apply until terminated by either
                you or us as set forth below.
              </li>
              <li>
                Termination by You: If You wish not to be bound by these Terms,
                you may terminate your relationship with us by providing a
                written notice of the same to NG. Upon receipt of your written
                notice regarding your intention to not be bound by these Terms
                and not avail the Services, NG will facilitate deletion of your
                Account and notify the same to you.
              </li>
              <li>
                Termination by Us: NG may at its discretion and at any time with
                or without notice, terminate or suspend the Terms and delete
                your Account, with or without cause if:
              </li>

              <ul className="terms-lowerAlphabet-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-1">
                <li>
                  You breach any of the provisions of the Terms, the Privacy
                  Policy or any other terms, conditions, or policies that may be
                  applicable to you;
                </li>
                <li>NG is required to do so in accordance with law; or</li>
                <li>
                  NG has elected to discontinue, with or without reason, access
                  to the Platform and/ or the Services (or any part thereof)
                  either in general or specifically to you.{" "}
                </li>
              </ul>

              <li>
                NG shall not be liable to you or any third party for any such
                termination.
              </li>
            </ul>

            {/* Fifteenth Main Point Here */}
            <li>CONSEQUENCES OF TERMINATION</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                Once your Account has been terminated, any and all content will
                be irretrievably deleted by us, except to the extent that we are
                obliged to maintain or permitted to retain in accordance with
                law. However, your transactions details may be preserved by the
                Company for purposes of tax or regulatory compliance.
              </li>
              <li>
                The licences granted to you in terms of these Terms shall stand
                terminated effective immediately.
              </li>
              <li>
                NG, in its sole discretion, may initiate appropriate legal
                proceedings against you, if necessary.
              </li>
              <li>
                Termination shall not affect your liability or obligations
                arising prior to such termination and any and all amounts and
                charges payable by you pursuant to access or use of the Platform
                and/or Services shall become immediately due and payable.
              </li>
            </ul>

            {/* Sixteenth Main Point Here */}
            <li>NOTICE</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                All notices from NG will be served by email to Your registered
                email address or by general notification on the Platform. Any
                notice provided to NG pursuant to the Terms of Use should be
                sent to [●] with subject line - Attention: “TERMS OF USE”.
              </li>
            </ul>

            {/* Seventeenth Main Point Here */}
            <li>GENERAL</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                To the extent that anything in or associated with the Platform
                is in conflict or inconsistent with these Terms, these Terms
                shall take precedence and prevail. Our failure to enforce any
                provision of these Terms shall not be deemed a waiver of such
                provision nor of the right to enforce such provision. Our rights
                under these Terms shall survive any discontinuance of the access
                or use of the Platform and/or Services;
              </li>
              <li>
                These Terms together with the Privacy Policy, any service
                agreement entered between you and NG in relation to the Platform
                and any other specific terms as may be set forth in the Platform
                shall constitute the entire agreement between you and NG; and
              </li>
              <li>
                If any provision of these Terms is held to be invalid or
                unenforceable, such provision shall be struck and the remaining
                provisions shall be enforced to the fullest extent under law.
              </li>
            </ul>

            {/* Eighteenth Main Point Here */}
            <li>DISPUTE RESOLUTION AND GOVERNING LAW</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                If there is a grievance or concern in respect of these Terms or
                the Services provided by NG, you are requested to email us
                details of the same at hello@thenetgala.in. Any concern shall be
                sought to be addressed by NG and in the event of any dispute,
                You and NG shall endeavour to amicably resolve the dispute.
              </li>
              <li>
                These Terms shall be governed and construed under the laws of
                India and the courts of Bangalore shall have exclusive
                jurisdiction.
              </li>
            </ul>

            {/* Nineteenth Main Point Here */}
            <li>GRIEVANCE REDRESSAL MECHANISM</li>
            <ul className="terms-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm flex flex-col gap-2">
              <li>
                NG has constituted appropriate grievance redressal mechanism
                within the organization to resolve any grievances of the Users.
                NG has designated an officer to redress the genuine grievances
                of the Users, the details of whom are stated herein below
                (“Grievance Redressal Officer”).
              </li>
              <li>
                In the event of any clarifications/queries/complaints/grievances
                in respect of the Services and/ or the Platform provided by NG,
                you shall first direct the same to the customer care center by
                phone at +91 8105583339 or by email at{" "}
                <a
                  className="text-[var(--reddish-brown)]  "
                  href="mailto:hello@thenetgala.in"
                >
                  hello@thenetgala.in
                </a>
                . In the event you are not satisfied with the assistance or
                response provided by our customer care, you shall then direct
                any such grievances pertaining to the Services and/or the use of
                the Platform, to the Grievance Redressal Officer of NG who shall
                ensure that genuine grievances of the Users are redressed
                promptly. The Grievance Redressal Officer shall endeavor to
                acknowledge the receipt of any complaint within 48 (forty-eight)
                hours and redresses the complaint within 1 (one) month from the
                date of receipt of the complaint.
              </li>
            </ul>
          </ul>


          <div className="flex flex-col gap-3">
          <p>Details of the Grievance Redressal Officer:</p>
          <p>Name: Shayoni Nair</p>
          <p>Designation: Founder</p>
          <p>
            Email:{" "}
            <a
              className="text-[var(--reddish-brown)]  "
              href="mailto:hello@thenetgala.in"
            >
              hello@thenetgala.in
            </a>
          </p>
          <p>Phone: +91-9871438591</p>
          <p>Address: #38 Kasturba Road Cross, Bangalore 560001</p>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Terms;
