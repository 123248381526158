import React from 'react'
import "./stayInTouchCard.css";
import StayInTouchCarousel from './StayInTouchCarousel';


const stayInTouchCard = ({onOpenStayInTouchModal}) => {
    return (
        <div class="full-container w-full md:bg-[var(--light-yellow)] bg-[var(--very-light-yellow)] flex justify-center items-center py-10 md:py-16" >

            <div className="card-container pb-8 md:py-24 md:w-[95%] w-[90%] flex flex-col-reverse md:flex-row rounded-badge md:p-8 p-4 justify-center items-center " >

                {/* Card Left Part */}
                <div className="stayInTouchCard-left-part md:basis-1/2 text-[var(--purple-blue)]">

                    {/* Heading Part */}
                    <div className="stayinTouchCard-heading md:text-left 2xl:text-6xl  md:text-5xl text-[2.5rem] font-ClashGrotesk  font-medium md:pb-10 mb-3 w-[75%] md:w-auto">Stay in touch with us!</div>

                    {/* Description and button */}
                    <div class="flex flex-col md:items-start text-center font-manrope">
                        <p className="stayInTouch-card-description 2xl:text-2xl md:text-xl text-base text-left font-medium mb-7 md:w-[92%]">Sign up for updates on email or WhatsApp, and share your interests. We’ll make sure you get the updates you’ll love.</p>
                        <button onClick={onOpenStayInTouchModal} className="stayInTouch-card-btn 2xl:text-xl md:text-lg text-sm bg-[var(--purple-blue)]  py-4 md:w-[60%]  px-14 text-[var(--white)] rounded-xl text-center">Sign Up for Updates</button>
                    </div>
                </div>

                {/* Card Right Part */}
                <div className="stayInTouchCard-right-part md:w-1/2 w-full flex  justify-start items-start mb-6 md:mb-0 ">



                    <div class="md:w-[90%] w-full flex flex-col  items-center justify-center pt-3 md:pt-0">

                        <StayInTouchCarousel></StayInTouchCarousel>
                    </div>



                </div>

            </div>

        </div>
    )
}

export default stayInTouchCard;
