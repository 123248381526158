import React from 'react'
import './brandAndCommunity.css'
import NykaaFashion from "../../../Asset/Nykaa-Fashion.svg"
import KicaBrand from "../../../Asset/kica-brand.svg"
import GBrand from "../../../Asset/G-brand.svg"
import DlfBrand from "../../../Asset/DLF.NS_BIG.svg"
import BudweiserBrand from "../../../Asset/budweiser-brand.svg"
import UltraHumanBrand from "../../../Asset/ultra-human-brand.png"
import GatoradeBrand from "../../../Asset/Gatorade.png"
import VanillaMoonBrand from "../../../Asset/VanillaMoon-brand.png"
import ClinikallyBrand from "../../../Asset/Clinikally-Logo-White.png"
import SavikalpaBrand from  "../../../Asset/Savikalpa-Logo-White.png"
import LalitHospitalityBrand from "../../../Asset/Lalit-Hospitality.png"

const brand = () => {
  return (

    // Brands Go here
    <div class="w-full brand-main flex flex-col flex-wrap overflow-hidden pattern-container ">

      <div className="heading-brand flex flex-col flex-wrap pt-10 md:pt-20 2xl:pt-28">
        <p class="text-[#E0E0E0] text-4xl md:text-[3.5rem]  text-left p-4 pl-6 md:pl-8 2xl:pl-12 font-ClashGrotesk font-[470] mb-4">Brands we have teamed up with</p>

        {/* All brand images here */}
        <div className="carousel  w-[100vw] ">
          <div className="carousel-item  w-[32vw] md:w-[19vw] bg-[#320F774F] p-1 border border-solid border-[#FFFFFF33]">
            <img className='w-full p-6 md:p-12'
              src={NykaaFashion}
              alt="Nykaa logo" />
          </div>
          <div className="carousel-item w-[32vw] md:w-[19vw] bg-[#320F774F] p-1 border border-solid border-[#FFFFFF33]">
            <img className='w-full p-6 md:p-12'
              src={DlfBrand}
              alt="Dlf Brand logo" />
          </div>
          <div className="carousel-item w-[32vw] md:w-[19vw] bg-[#320F774F] p-1 border border-solid border-[#FFFFFF33]">
            <img className='w-full p-6 md:p-12'
              src={UltraHumanBrand}
              alt="UltraHuman brand logo" />
          </div>
          <div className="carousel-item w-[32vw] md:w-[19vw] bg-[#320F774F] p-1 border border-solid border-[#FFFFFF33]">
            <img className='w-full p-6 md:p-12'
              src={GatoradeBrand}
              alt="Gatorade logo" />
          </div>
          <div className="carousel-item  w-[32vw] md:w-[19vw] bg-[#320F774F] p-1 border border-solid border-[#FFFFFF33]">
            <img className='w-full p-6 md:p-12'
              src={VanillaMoonBrand}
              alt="vanilla Moon Brand logo" />
          </div>

    
          <div className="carousel-item  w-[32vw] md:w-[19vw] bg-[#320F774F] p-1 border border-solid border-[#FFFFFF33]">
            <img className='w-full p-6 md:p-12'
              src={ClinikallyBrand}
              alt="Clinikally logo" />
          </div>
          <div className="carousel-item w-[32vw] md:w-[19vw] bg-[#320F774F] p-1 border border-solid border-[#FFFFFF33]">
            <img className='w-full p-6 md:p-12'
              src={SavikalpaBrand}
              alt="Savikalpa Brand logo" />
          </div>
          <div className="carousel-item w-[32vw] md:w-[19vw] bg-[#320F774F] p-1 border border-solid border-[#FFFFFF33]">
            <img className='w-full p-6 md:p-12'
              src={LalitHospitalityBrand}
              alt="Lalit Hospitality brand logo" />
          </div>
          
        </div>
    


      </div>



    </div>
    
  )
}

export default brand
