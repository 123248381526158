import React, { useState, useEffect } from "react";
import LeaveCheckout from "./LeaveCheckout";
import { Link } from "react-router-dom";
import calendarVector from "../../../Asset/Events/calendar-vector.svg";
import locationVector from "../../../Asset/Events/location-vector.svg";
import timeVector from "../../../Asset/Events/time-vector.svg";
import { format } from "date-fns";
import axios from "axios";
import { useParams } from "react-router-dom";
import './CheckoutModal.css'


const CheckoutModal = ({ isOpen, closeModal, count, eventData }) => {
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [formattedDates, setFormattedDates] = useState({});
  let { eventId } = useParams();
  const [errors, setErrors] = useState({});



  //Loading Razorpay SDK dynamically
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  }, []);

  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    ext: "",
    gender: "", // Default to "Man"
    firstname: "",
    lastname: "",
    org_id: "",
    role: "General", // Default role to "General"
    agreeTerms: false,
    subscribeUpdates: false
  });

  // State for team members
  const [teamMembers, setTeamMembers] = useState(
    Array.from({ length: count }, () => ({
      firstname: '',
      lastname: '',
      phone: '',

      email: null,

      gender: '' // Initialize with empty string
    }))
  );

  // State to store user ID from the signup response
  const [userId, setUserId] = useState(null);
  const [orderId, setOrderId] = useState(null);

  // Check if all input fields are filled and checkbox is checked
  const isFormValid = 
    formData.firstname.trim() !== '' &&
    formData.lastname.trim() !== '' &&
    formData.email.trim() !== '' &&
    formData.phone.trim() !== '' &&
    formData.gender !== '' && 
    formData.agreeTerms;

  // Handle input change for signup form
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // setFormData((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });


    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers[0][name] = value;
    setTeamMembers(updatedTeamMembers);
  };

  // Handle input change for individual fields
  const handleTeamInputChange = (index, field, value) => {
    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers[index][field] = value;
    setTeamMembers(updatedTeamMembers);
    // validateFields(field);
  };

  //validating form fields
  const validateFields = () => {
    const newErrors = {};
    if (!formData.firstname) newErrors.firstname = true;
    if (!formData.lastname) newErrors.lastname = true;
    if (!validateEmail(formData.email)) newErrors.email = true;
    if (!validateMobile(formData.phone)) newErrors.phone = true;
    if (!formData.gender) newErrors.gender = true;
    if (!formData.agreeTerms) newErrors.agreeTerms = true;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);

  };

  const validateMobile = (phone) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(phone);
  };

  // Function to handle form submission for signup, orders and checkout endpoints
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()){
      
      return;
    } 



    try {
      // First, make the POST request to /signup endpoint
      const signupResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/signup/`,
        {
          email: formData.email,
          mobile: formData.phone,
          MobileNumberExt: "+91",
          first_name: formData.firstname,
          last_name: formData.lastname,
          org_id: formData.org_id || "", // Ensure org_id is included or defaults to empty string
          role: formData.role,
          ext: formData.ext || "", // Optional
          gender: formData.gender,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );

      // Extract and store ID from the /signup response
      console.log("Event id is: ", eventId)
      console.log("Signup response", signupResponse.data);
      const { id } = signupResponse.data;
      setUserId(id);


      // Now, make the POST request to the /orders endpoint using the generated userId
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/orders/`,
        {
          EventId: eventId,
          primaryUserId: id, // Use the ID returned from the signup response
          OrderData: {
            Team: teamMembers
          },
          "IsUsingCredits": false,
          WaitlistOrderId: null, // Optional, can be left blank if not provided
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );

      const { OrderId } = orderResponse.data;
      setOrderId(OrderId);

      console.log("Order ID Generated:", OrderId);

      // Checkout Endpoint code here
      // Finally, make the POST request to the /checkout endpoint using the generated OrderId

      const checkoutResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/checkout/?order_id=${OrderId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );
      console.log("Checkout Data: ", checkoutResponse.data);

      //storing data to proceed with razorpay
      const { RazorpayOrderId, amount } = checkoutResponse.data;
      console.log("RazorpayOrderId", RazorpayOrderId);

      //Call Razorpay payment handling function
      handleRazorpayPayment(RazorpayOrderId, formData, amount);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleRazorpayPayment = async (RazorpayOrderId, formData, amount) => {
    amount = amount * 100 * count;
    //Dynamically create a form to submit to Razorpay Hosted Checkout
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute(
      "action",
      "https://api.razorpay.com/v1/checkout/embedded"
    );
    form.setAttribute("target", "_blank");

    //Define form fields dynamically
    const fields = [
      { name: "key_id", value: "rzp_test_w402YRQwCZ0zlG" }, // Your Razorpay key ID
      { name: "order_id", value: RazorpayOrderId }, // The order ID returned from backend
      { name: "amount", value: amount * 100 }, // Amount in paise (multiply INR by 100)
      { name: "currency", value: "INR" },

      { name: "name", value: "Net Gala" },
      { name: "description", value: eventData.EventDescription },
      { name: "image", value: `${process.env.PUBLIC_URL}/logo.svg` }, // Company logo or product image
      // { name: "image", value: `https://ibb.co/wM7K7Kc` }, // Company logo or product image
      {
        name: "callback_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment-callback/`,
      },
      {
        name: "cancel_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment-callback`,
      },
      { name: "prefill[name]", value: formData.firstname }, // Optional pre-fill for name
      { name: "prefill[contact]", value: formData.phone }, // Optional pre-fill for contact
      { name: "prefill[email]", value: formData.email }, // Optional pre-fill for email
    ];

    //Append fields to the form
    fields.forEach(({ name, value }) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", name);
      input.setAttribute("value", value);
      form.appendChild(input);
    });

    // Append form to the body and submit it automatically
    document.body.appendChild(form);
    form.submit();
    console.log(form);


  };




  // Format dates when eventData is received
  useEffect(() => {
    if (eventData?.event_schedule) {
      const startDate = format(new Date(eventData.event_schedule.StartDate), "do MMM, EEEE, yyyy");
      const endDate = format(new Date(eventData.event_schedule.EndDate), "do MMM, EEEE, yyyy");
      const registrationStartDate = format(new Date(eventData.event_schedule.RegistrationStartDate), "dd MMM, EEEE yyyy");
      const registrationEndDate = format(new Date(eventData.event_schedule.RegistrationEndDate), "dd MMM, EEEE yyyy");
      const StartTimeStamp = format(new Date(eventData.event_schedule.StartTimeStamp), "hh:mm a");
      const EndTimeStamp = format(new Date(eventData.event_schedule.EndTimeStamp), "hh:mm a");

      setFormattedDates({
        startDate,
        endDate,
        registrationStartDate,
        registrationEndDate,
        StartTimeStamp,
        EndTimeStamp,
      });
    }
  }, [eventData]);

  const handleCloseClick = () => {
    setShowLeaveModal(true);
  };

  const handleStayClick = () => {
    setShowLeaveModal(false);
  };

  const handleLeaveClick = () => {
    setShowLeaveModal(false);
    closeModal();
  };

  //prevents scrolling of page when modal opened and resumes scrolling when modal closed
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => document.body.style.overflow = 'unset';
  }, []);

  if (!isOpen) return null;

  return (
    <div id="blackOverView-checkout" className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 object-cover">
      {!showLeaveModal ? (
        <div id="main-container-checkout" className="bg-[var(--white)] md:rounded-3xl w-full h-full md:h-auto md:w-[80vw]  md:max-h-[95vh] md:mx-4 flex flex-col md:flex-row justify-between relative ">
          {/* Left Side: Registration Form */}
          <div id="left-container-checkout" className="w-full md:w-[58%] border-r  md:pr-8 p-4  md:max-h-[70vh] overflow-hidden md:p-14">
            <div id="leftSide-top-heading-checkout" className="flex justify-between items-center ">
              <h2 className="text-[1.75rem] md:text-3xl lg:text-[2rem] font-medium font-ClashGrotesk text-[var(--dark-blue)]">
                Checkout
              </h2>

              {/* close buttone here */}
              <button
                className="text-gray-500 z-20 absolute top-3 right-3 md:top-3 md:right-4 md:text-xl"
                onClick={handleCloseClick}
              >
                &#x2715;
              </button>
            </div>

            <div className="mt-3  flex flex-col  h-[50vh] overflow-hidden">
              <span className="mb-4 flex justify-between">
                <p className="text-base md:text-lg lg:text-xl font-semibold font-manrope text-[var(--dark-blue)] mb-2">Add registration details for all players</p>
                <p className="font-manrope text-xs mt-1"><span className="text-red-600">*</span> Required</p>
              </span>

              {/* Scrollable Form */}
              <div className="flex flex-col justify-center  overflow-hidden ">
                <hr className="text-[var(--dull-white)] border-[1.04px] md:mb-1 hidden md:block" />
                <div id="scroll-container" className="scroll-container max-h-[32vh] md:max-h-[60vh] md:pr-2 font-manrope  justify-center md:pb-4 overflow-y-auto">
                  <form className="flex flex-col px-4 md:px-0 gap-3">



                    <h1 className="text-base md:text-xs font-semibold font-manrope text-[var(--gray-brown)] md:ml-10 pt-2 hidden md:block">
                      Organizer Contact Details:
                    </h1>{" "}

                    {Array.from({ length: count }, (_, i) => (
                      <React.Fragment key={i}>
                        {i === 0 ? (
                          <>
                            <div id="form-mainPlayer-part-checkout" className="flex flex-col md:flex-row gap-4 w-full text-[var(--dark-blue)] font-manrope font-semibold border border-solid rounded-3xl md:border-none p-8 md:p-0 ">
                              <p className="font-semibold font-manrope text-[var(--dark-blue)] md:hidden">Your Details</p>
                              <p className="text-[var(--dark-blue)]  border-[var(--dark-blue)] border-solid border-2 rounded-full w-6 h-6  p-2 md:flex justify-center items-center mt-2 hidden ">{i + 1}</p>
                              <div className="flex flex-col w-full gap-4">
                                <div className="flex flex-col md:flex-row gap-4  ">
                                  <input required
                                    type="text"
                                    placeholder="First Name*"
                                    name="firstname"
                                    value={formData.firstname}
                                    onChange={handleChange}
                                    className={` border py-4 p-2 rounded-lg w-full  bg-[var(--white)] md:text-xs ${errors.firstname ? 'border-red-500' : 'border-[var(--input-border-gray)]'}`}
                                  />
                                  <input required
                                    type="text"
                                    placeholder="Last Name*"
                                    name="lastname"
                                    value={formData.lastname}
                                    onChange={handleChange}
                                    className={`border py-4 p-2 rounded-lg w-full  bg-[var(--white)] md:text-xs ${errors.lastname ? 'border-red-500' : 'border-[var(--input-border-gray)]'} `}
                                  />
                                </div>
                                <div className="flex flex-col md:flex-row gap-4">
                                  <input
                                    type="email"
                                    placeholder="Email*"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`border py-4  p-2 rounded-lg w-full  bg-[var(--white)] text-[var(--dark-blue)] md:text-xs ${errors.email ? 'border-red-500' : 'border-[var(--input-border-gray)]'}`}
                                  />
                                  <select name="gender" value={formData.gender} onChange={handleChange} className={` custom-select select md:py-4  md:p-2  w-full   max-w-xs  text-[var(--dark-blue)]  font-semibold md:text-xs ${errors.gender ? 'border-red-500' : 'border-[var(--input-border-gray)]'} `} required>
                                    <option disabled selected hidden value="" className="text-[var(--gray-brown)] font-ClashGrotesk font-medium ">Gender</option>
                                    <option value="Man" >Man</option>
                                    <option value="Woman" >Woman</option>
                                    <option value="Non-Binary">Non-Binary</option>
                                  </select>
                                  <input
                                    type="tel"
                                    placeholder="Mobile*"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className={`border  py-4 p-2 rounded-lg w-full  bg-[var(--white)] text-[var(--dark-blue)] md:text-xs ${errors.phone ? 'border-red-500' : 'border-[var(--input-border-gray)]'} `}
                                  />

                                </div>
                              </div>


                            </div>




                            <br />
                            <hr className="text-[var(--dull-white)] md:-mt-6 border-[1.04px] mb-0 hidden md:block" />


                          </>

                        ) : (
                          <>
                            {i === 1 ? (
                              <p className="mt-2 md:ml-10 md:text-xs font-manrope font-semibold text-[var(--gray-brown)] hidden md:block">
                                Well done! Now, fill in the details for the rest
                                of your team members
                              </p>
                            ) : (
                              <p>

                              </p>
                            )}

                            <div id="form-loop-part-checkout" className="flex flex-col md:flex-row gap-4 w-full md:items-start font-manrope  text-[var(--dark-blue)] mb-2 font-semibold border border-solid rounded-3xl md:border-none p-8 md:p-0  ">
                              <span className="flex items-center gap-2 md:mt-2">
                                <p className="text-[var(--dark-blue)]  border-[var(--dark-blue)] border-solid border-2 rounded-full w-5 h-5 md:w-6 md:h-6  p-2 flex justify-center items-center mt-1 text-sm md:text-base ">{i + 1}</p>

                                <p className="font-semibold font-manrope text-[var(--dark-blue)] md:hidden pt-1">Team Member {i}</p>

                              </span>

                              <div className="flex flex-col w-full gap-4">
                                <div className="flex flex-col md:flex-row gap-4 w-full ">
                                  <input
                                    type="text"
                                    placeholder="First Name*"
                                    name="first_name"
                                    value={teamMembers[i].firstname}
                                    onChange={(e) => handleTeamInputChange(i, 'firstname', e.target.value)}
                                    required
                                    className={`border py-4 p-2 rounded-lg w-full bg-[var(--white)] md:text-xs ${errors.firstname ? "border-red-500" : 'border-[var(--input-border-gray)]'}`}
                                  />
                                  <input
                                    type="text"
                                    placeholder="Last Name*"
                                    name="last_name"
                                    value={teamMembers[i].lastname}
                                    onChange={(e) => handleTeamInputChange(i, 'lastname', e.target.value)}
                                    required
                                    className={`border py-4 p-2 rounded-lg w-full bg-[var(--white)] md:text-xs ${errors.lastname ? "border-red-500" : "border-[var(--input-border-gray)]"}`}
                                  />
                                </div>
                                <div className="flex flex-col md:flex-row gap-4">

                                  <select name="gender"
                                    value={teamMembers[i].gender}
                                    onChange={(e) => handleTeamInputChange(i, 'gender', e.target.value)}
                                    required
                                    className={`select md:py-4 p-2    w-full   text-[var(--dark-blue)] font-semibold md:text-xs ${errors.gender ? "border-red-500" : "border-[var(--input-border-gray)] "}`} >
                                    <option disabled selected hidden value="" className="text-[var(--gray-brown)] font-ClashGrotesk font-medium ">Gender</option>
                                    <option value="Man" >Man</option>
                                    <option value="Woman" >Woman</option>
                                    <option value="Non-Binary">Non-Binary</option>
                                  </select>
                                  <input
                                    type="tel"
                                    placeholder="Mobile*"
                                    name="mobile"
                                    value={teamMembers[i].phone}
                                    onChange={(e) => handleTeamInputChange(i, 'phone', e.target.value)}
                                    required
                                    className={`border py-4 p-2 rounded-lg w-full bg-[var(--white)] text-[var(--dark-blue)] md:text-xs ${errors.phone ? "border-red-500" : "border-[var(--input-border-gray)]"}`}
                                  />

                                </div>
                              </div>
                            </div>
                          </>
                        )}


                      </React.Fragment>
                    ))}

                  </form>

                </div>
              </div>
            </div>
          </div>

          {/* Right Side: Order Summary */}
          <div id="right-part-checkout" className="w-[90%] mx-auto md:mx-0 flex flex-col gap-2 md:w-[42%] bg-[var(--background-light-gray)] py-2 px-6 md:p-10 mb-8 md:mb-0 md:mt-0 md:max-h-[30%]  md:rounded-r-3xl rounded-3xl md:rounded-none  md:rounded-bl-none relative ">
            <div className="flex justify-between">
              <h3 className="font-semibold font-manrope text-base text-[var(--dark-blue)] hidden md:block">
                Order Summary
              </h3>
            </div>

            {/* Event Title and description for right side only for web view*/}
            <div className="mt-4 font-manrope font-semibold hidden md:block">
              <p className="text-[var(--dark-blue)] font-bold text-xl mb-1">
                {eventData.EventTitle} x {count}
              </p>
              <hr className="text-[var(--input-border-gray)]  border-[1.5px] my-4 md:my-2 " />
              <p className="text-[var(--gray-brown)]  mt-2 flex items-center space-x-2">
                <i className="far fa-calendar-alt"></i>
                <span className="flex gap-2 md:text-base">
                  <img src={calendarVector} alt="calendar" />
                  {formattedDates.startDate}
                </span>
              </p>
              <hr className="text-[var(--input-border-gray)]  border-[1.5px] my-2 hidden md:block" />
              <p className="text-[var(--gray-brown)] mt-2 flex items-center space-x-2">
                <i className="far fa-clock"></i>
                <span className="flex gap-2 md:text-base">
                  <img src={timeVector} alt="clock" />
                  {formattedDates.StartTimeStamp} -{" "}
                  {formattedDates.EndTimeStamp}
                </span>
              </p>
              <hr className="text-[var(--input-border-gray)]  border-[1.5px] my-2 hidden md:block" />
              <p className="text-[var(--gray-brown)] mt-2 flex items-center space-x-2">
                <i className="fas fa-map-marker-alt"></i>
                <span className="flex gap-2 md:text-base">
                  <img src={locationVector} alt="location" />
                  {eventData.venue.venue_address.city},{" "}
                  {eventData.venue.venue_address.state},{" "}
                  {eventData.venue.venue_address.country},{" "}
                  {eventData.venue.venue_address.pincode}
                </span>
              </p>
              <hr className="text-[var(--input-border-gray)] border-[1.5px] my-2 mt-4 md:mt-0" />
            </div>

            {/* Event Title and description as accordian for mobile device */}
            <div className="md:hidden mt-4 font-manrope font-semibold">
              <div className="collapse collapse-arrow">
                <input type="checkbox" name="my-accordion-1" defaultChecked />
                {/* Title here */}
                <div className="collapse-title text-xl font-medium">
                  <p className="text-[var(--dark-blue)] font-bold text-xl mb-1 -ml-3">
                    {eventData.EventTitle} x {count}
                  </p>
                  <hr className="text-[var(--input-border-gray)]  border-[1.5px] my-2 md:my-2 -ml-3" />
                </div>
                <div className="collapse-content p-0">
                  <p className="text-[var(--gray-brown)]  mt-2 flex items-center space-x-2">
                    <i className="far fa-calendar-alt"></i>
                    <span className="flex gap-2 md:text-base">
                      <img src={calendarVector} alt="calendar" />
                      {formattedDates.startDate}
                    </span>
                  </p>

                  <p className="text-[var(--gray-brown)] mt-2 flex items-center space-x-2">
                    <i className="far fa-clock"></i>
                    <span className="flex gap-2 md:text-base">
                      <img src={timeVector} alt="clock" />
                      {formattedDates.StartTimeStamp} -{" "}
                      {formattedDates.EndTimeStamp}
                    </span>
                  </p>

                  <p className="text-[var(--gray-brown)] mt-2 flex items-center space-x-2">
                    <i className="fas fa-map-marker-alt"></i>
                    <span className="flex gap-2 md:text-base">
                      <img src={locationVector} alt="location" />
                      {eventData.venue.venue_address.city},{" "}
                      {eventData.venue.venue_address.state},{" "}
                      {eventData.venue.venue_address.country},{" "}
                      {eventData.venue.venue_address.pincode}
                    </span>
                  </p>
                  <hr className="text-[var(--input-border-gray)] border-[1.5px] mt-4  md:mt-0 md:hidden" />
                </div>
                
              </div>
            </div>



            {/* Checkbox for subscription and terms and conditions */}
            <form id="agree-checkboxForm-checkout" className="overflow-hidden md:mt-2 mb-2 md:mb-0">
              <div className="md:mt-4 font-manrope font-semibold text-sm text-[var(--dark-blue)]">
                <div className="checkbox-container md:flex md:items-center space-x-2 flex items-center">
                  <input className="custom-checkbox" type="checkbox" defaultChecked id="newsletter" name="subscribeUpdates" checked={formData.subscribeUpdates} onChange={handleChange} />
                  <label className="flex shrink-0" htmlFor="newsletter"></label>
                  <span>Keep me updated on more events and news from Net Gala</span>
                </div>
                <div className="checkbox-container flex items-start md:items-center space-x-2 mt-2">
                  <input className={'custom-checkbox'} type="checkbox" defaultChecked id="terms" name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} />
                  <label className="flex shrink-0" htmlFor="terms" style={errors.agreeTerms ? { borderColor: 'rgba(239, 68, 68, 1)' } : {}}>
                  </label>
                  <div>
                    I agree to the{" "}
                    <span className="underline">
                      <Link to="/terms" target="_blank" className="hover:text-[var(--reddish-brown)]">
                        Net Gala Terms of Service
                      </Link>
                    </span>
                  </div>
                </div>
                
              </div>
            </form>

            <div className="md:mt-auto">
              {/* Event Price here */}
              <div className="md:mt-4 md:pt-4 mb-4 md:mb-0">
                <p className="font-normal font-ClashGrotesk text-2xl text-[var(--dark-blue)]">
                  Total <span className="font-medium">₹{eventData.Price * count}</span>
                </p>
              </div>

              {/* Proceed for payment button here */}
              <button
                onClick={handleSubmit}
                
                className={`w-full mb-3 md:mb-0 mx-auto  font-manrope font-semibold text-[var(--white)] py-4 rounded-2xl  md:text-[1.1rem] ${!isFormValid? 'bg-[var(--button-disable-light-gray)] cursor-default' : 'bg-[var(--purple-blue)]'}`}
              >
                Proceed to Payment
              </button>
            </div>

          </div>
        </div>
      ) : (
        <LeaveCheckout onStay={handleStayClick} onLeave={handleLeaveClick} />
      )}
    </div>
  );
};

export default CheckoutModal;
