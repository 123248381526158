import React from 'react'
import BlackWhiteFootball from "../../../Asset/About/black-white-football.png"
import "../About.css"

const BottomContent = () => {
    return (
        <>
            <div className='mt-24 2xl:mt-28 pb-16 md:pb-0 w-full flex justify-center'>
                <div id='about-bottom-content' className='md:w-[95%] w-[90%] bg-white flex flex-col-reverse md:flex-row rounded-2xl gap-8 md:gap-0 p-6 md:p-8 2xl:p-12'>

                    {/* Left Section Here */}
                    <div className='md:w-[45%] flex flex-col text-[var(--purple-blue)] gap-6 2xl:gap-8'>
                        <h1 className='font-ClashGrotesk text-3xl md:text-4xl xl:text-6xl  font-medium'>Corporates and Groups, we’ve got something for you too</h1>
                        <p className='md:text-xl 2xl:text-2xl font-medium font-manrope'>We’d love to hear from you and collaborate on building tailor-made membership offerings that fit your organisation perfectly</p>
                        <button 
                        className="bg-[var(--purple-blue)] md:text-lg 2xl:text-xl text-[var(--white)] font-manrope font-semibold md:w-[72%] w-[85%] mx-auto md:mx-0 p-4 rounded-xl"
                        onClick={() => window.location.href = 'mailto:hello@thenetgala.in'}
                        >
                        Schedule a Call Back
                        </button>                    
                    </div>

                    {/* Right Section Here */}
                    <div className='md:w-1/2 flex justify-center items-center '>
                        <div className='md:w-[80%] '>
                            <img className='w-full' src={BlackWhiteFootball} alt="Black and white football" />
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default BottomContent
