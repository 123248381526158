import React from 'react';

const LeaveCheckout = ({ onStay, onLeave }) => {
  return (
    <div className="bg-[var(--background-light-gray)] p-6 md:p-8 rounded-2xl shadow-xl w-full md:h-[65vh] max-w-6xl  mx-2 sm:mx-auto flex flex-col items-center justify-center gap-6">
      {/* Title */}
      <h1 className="font-ClashGrotesk text-center text-xl md:text-3xl lg:text-[2rem] font-[520] text-[var(--dark-blue)] ">
        Leave Checkout?
      </h1>
      
      {/* Message */}
      <p className=" text-sm md:text-lg lg:text-xl font-[520] font-manrope text-[var(--dark-blue)] mb-6 md:w-[43%] text-center ">
        Are you sure you want to leave checkout? The registration details that you’ve added will be lost.
      </p>
      
      {/* Buttons */}
      <div className="flex justify-center gap-4 w-full md:w-[43%] ">
        {/* Stay Button */}
        <button 
          className="w-full md:px-20 py-4 md:py-3 bg-[var(--white)] text-[var(--dark-blue)]  rounded-xl  font-manrope font-[620] md:text-base"
          onClick={onStay}
        >
          Stay
        </button>
        
        {/* Leave Button */}
        <button 
          className="w-full md:px-20 py-4 md:py-3 bg-[var(--purple-blue)] text-[var(--white)] rounded-xl  font-manrope font-[620] md:text-base"
          onClick={onLeave}
        >
          Leave
        </button>
      </div>
    </div>
  );
};

export default LeaveCheckout;
